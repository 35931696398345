// const baseURL = 'http://localhost:5000';
// const baseURL = 'https://bbd.softmaxai.com/api';
// const baseURL = 'https://portal.bbdtel.com/api';

const getBaseUrl = () => {
    const currentDomain = window.location.hostname;
    if(currentDomain === 'localhost'){
        return 'http://localhost:5000'; 
    }else{
        return `https://${currentDomain}/api`;
    }
};

const baseURL = getBaseUrl();

export default baseURL;
