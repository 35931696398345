import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import baseUrl from "../../BaseUrl";
import axios from 'axios';

const ApiSecretGeneration = ({baseData, updatedTNSDetail}) => {
    // console.log(baseData);
    const [copied, setCopied] = useState(false);
    const [countUpdated, setCountUpdated] = useState(false)
    const [formData, setFormData] = useState({
        cid: '',
        email: "",
        api_enable_switch: false,
        api_access_count:null,
        api_username:'',
        api_secret:"",
        api_daily_switch : false,
        api_daily_access_count: ''  
    });
    useEffect(() => {
        if(baseData){
            const apiKey = uuidv4();
            setFormData((prevData) => ({
                ...prevData,
                cid: baseData.cid,
                email: baseData.email,
                api_enable_switch : baseData.api_enable_switch,
                api_access_count:baseData.api_access_count ?  baseData.api_access_count : 1000,
                api_username: baseData?.api_username ?? apiKey,
                api_secret: baseData.api_secret,
                api_daily_switch : baseData.api_daily_switch ?? true,
                api_daily_access_count: baseData.api_daily_access_count ? baseData.api_daily_access_count : 100 
            }));
        }
    }, [baseData]);

    // useEffect(() => {
    //     console.log(formData)
    // }, [formData]);
    
    // const handleSaveApiAccessCount = async (event) => {
    //     setFormData((prevData)=> ({
    //         ...prevData,
    //         api_access_count: event.target.value
    //     }))
    //     try {
    //         const response = await axios.post(`${baseUrl}/api-access-count`, {
    //           formData: {cid:formData.cid, api_access_count: formData.api_access_count}             
    //         });
    //         if(response.data.status === 'updated'){
    //             setCountUpdated(true)
    //             setTimeout(() => setCountUpdated(false), 2000); // Reset after 2 seconds
    //         }
    //       } catch (error) {
    //         console.error('Error fetching data:', error);
    //       }
    // }
    
    const handleE911SwitchChange = async(event) => {
        const { name, checked } = event.target;
        if(checked){
            // const apiKey = uuidv4();
            const api_secret = generatePassword();
            if(window.confirm("Are you sure you want to enable API access for this customer ?")){
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: checked,

                    api_secret: api_secret
                })); 
                try {
                  const response = await axios.post(`${baseUrl}/cognito-user-detail`, {
                    formData: { ...formData, [name]: checked, api_secret: api_secret }             
                  });
                  console.log('Response:', response.data);
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
            } 
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
                api_secret: "",
                api_access_count: 1000,
            }));
            try {
                const response = await axios.post(`${baseUrl}/cognito-user-detail`, {
                  formData: { ...formData, [name]: checked, api_secret: "",api_access_count:null }             
                });
                console.log('Response:', response.data);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }
    };
    const generatePassword = () => {
        const length = 25;
        const numbers = '0123456789';
        const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const specialCharacters = '^$*.[]{}()?-@#%&/,><|_~+=';
    
        const allCharacters = numbers + lowerCase + upperCase + specialCharacters;
    
        let password = '';
        let hasNumber = false;
        let hasLowerCase = false;
        let hasUpperCase = false;
        let hasSpecialCharacter = false;
    
        // Ensure the password contains at least one of each required character type
        password += numbers[Math.floor(Math.random() * numbers.length)];
        hasNumber = true;
    
        password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
        hasLowerCase = true;
    
        password += upperCase[Math.floor(Math.random() * upperCase.length)];
        hasUpperCase = true;
    
        password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
        hasSpecialCharacter = true;
    
        // Fill the rest of the password length with random characters
        for (let i = password.length; i < length; i++) {
            const character = allCharacters[Math.floor(Math.random() * allCharacters.length)];
            password += character;
    
            if (numbers.includes(character)) {
                hasNumber = true;
            } else if (lowerCase.includes(character)) {
                hasLowerCase = true;
            } else if (upperCase.includes(character)) {
                hasUpperCase = true;
            } else if (specialCharacters.includes(character)) {
                hasSpecialCharacter = true;
            }
        }
    
        // Shuffle the password to ensure randomness
        password = password.split('').sort(() => Math.random() - 0.5).join('');
    
        // Validate the generated password and regenerate if it doesn't meet all criteria
        if (!hasNumber || !hasLowerCase || !hasUpperCase || !hasSpecialCharacter) {
            return generatePassword();
        }
    
        return password;
    };
    const partiallyHideSecret = (secret) => {
        if(secret){
            const visibleChars = 4;
            const hiddenChars = secret.length - visibleChars;
            return `${secret.slice(0, visibleChars)}${'*'.repeat(hiddenChars)}`;
        }else{
           return secret
        }
       
    };

    const handleCopy = async (e, value) => {
        e.preventDefault();
        try {
          await navigator.clipboard.writeText(value);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        } catch (error) {
          console.error('Failed to copy:', error);
        }
    };

    const handleInputChange = async(event) => {
        const { name, checked, value } = event.target;
        
        // if(name === "api_daily_switch"){
        //     const count = formData.api_access_count >= 100 ? 100 : formData.api_access_count;
        //     setFormData((prevSetting) => ({
        //         ...prevSetting,
        //         [name]: checked,
        //         ["api_daily_access_count"] : count
        //     }));
        // }else if(name === "api_daily_access_count"){
        //     if(value > 100 || value > formData.api_access_count){
        //         alert("please set valid daily limit");
        //     }else{
        //         setFormData((prevSetting) => ({
        //             ...prevSetting,
        //             [name]: value,
        //         }));
        //     }
            
        // }else{
        //     setFormData((prevSetting) => ({
        //         ...prevSetting,
        //         [name]: value,
        //     }));
        // }
        setFormData((prevSetting) => {
            let updatedFormData = { ...prevSetting };

            if (name === "api_daily_switch") {
                const count = formData.api_access_count >= 100 ? 100 : formData.api_access_count;
                updatedFormData = {
                    ...prevSetting,
                    [name]: checked,
                    "api_daily_access_count": count,
                };
            } else if (name === "api_daily_access_count") {
                if (value > 100 || value > formData.api_access_count) {
                    alert("please set valid daily limit");
                } else {
                    updatedFormData = {
                        ...prevSetting,
                        [name]: value,
                    };
                }
            } else if (name === "api_access_count") {
                
                updatedFormData = {
                    ...prevSetting,
                    [name]: value,
                    "api_daily_access_count": value > 100 ? 100 : value
                };
                
            } else {
                updatedFormData = {
                    ...prevSetting,
                    [name]: value,
                };
            }

            // console.log(updatedFormData);
            updatedTNSDetail(updatedFormData);
            return updatedFormData;
        });
       
        
    };
    
    return (
        <>
        <div className="common-white-shadow-background setting-section">
            <div className="innerboxwidth">
                <div className="row">
                    <div className="header-row">
                        <h3>Enable API access</h3>
                        {/* <input type="button" className="yellow-button" value="Generate" onClick={handleGenerate} /> */}
                    </div>
                    
                    <div className="settingbox">
                        <label>Api Access</label>
                        <div className="switchbtn">
                            <input type="checkbox" id="api_enable_switch" className="checkbox" checked={formData.api_enable_switch === true} name="api_enable_switch" onChange={handleE911SwitchChange} /> <label
                                htmlFor="api_enable_switch" className="toggle">
                                <p>
                                    
                                </p>
                            </label>
                        </div>
                       
                    </div>

                    {formData.api_enable_switch && <div className='api-key-secret'>
                        <div className="row">
                            <div className="col-sm-2">
                                <label>Api Key</label>
                            </div>
                            <div className="col-sm-8">
                                <input type="text" name="api_ket" readOnly placeholder="Api Key" value={partiallyHideSecret(formData.api_username)} />
                            </div>
                            <div className="col-sm-2">
                                <input type="button" onClick={(e) => handleCopy(e, formData.api_username)} value="Copy"/>
                               
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-2">
                                <label>Api Secret</label>
                            </div>
                            <div className="col-sm-8">
                                <input type="text" name="api_secret" readOnly placeholder="Api Secret" value={partiallyHideSecret(formData.api_secret)}  />
                            </div>
                            <div className="col-sm-2">
                                <input type="button" onClick={(e) => handleCopy(e, formData.api_secret)} value="Copy"/>
                               
                            </div>
                        </div>
                        {copied && <span>Copied!</span>}
                        {countUpdated && <span>Access Count Updated!</span>}
                    </div>}
                </div>
            </div>
        </div>  
        <div className="common-white-shadow-background setting-section">
            <div className="innerboxwidth">
                <div className="row">
                    <div className="header-row">
                        <h3>TNS(Telephone Number Status) Configuration</h3>
                    </div>
                    
                    
                    {formData.api_enable_switch && <div className="settingbox">
                            <label>Monthly Usage Limit (Tel Numbers)</label>
                            <div className="col-sm-2">
                                <input style={{width: '60%'}} type="number" name="api_access_count"  value={formData.api_access_count} onChange={handleInputChange}  />
                            </div>
                    </div> }
                    {formData.api_enable_switch && 
                        <>
                        <div className="settingbox">
                            <label>Daily Usage Limit (Tel Numbers)</label>
                            <div className="switchbtn daily-limit">
                                <input type="checkbox" id="api_daily_switch" className="checkbox" checked={formData.api_daily_switch === true} name="api_daily_switch" onChange={handleInputChange} /> <label
                                    htmlFor="api_daily_switch" className="toggle">
                                    <p>

                                    </p>
                                </label>
                                {formData.api_daily_switch && 
                            
                            <div className="col-sm-2 daily-input">
                                <input 
                                    type="number" 
                                    name="api_daily_access_count" 
                                    value={formData.api_daily_access_count} 
                                    onChange={handleInputChange}
                                />
                            </div>
                        }
                            </div>
                        </div>
                       
                    </>}
                    
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default ApiSecretGeneration;