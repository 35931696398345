/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";

const TrafficAnalyticsReporting = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [cid, setCid] = useState('');
    const [inboundList, setInboundList] = useState([]);
    const [outboundList, setOutboundList] = useState([]);    
    const [role, setRole] = useState('');
    const [error, setError] = useState(null);
    const [access, setAccess] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);    
    const [boundType, setBoundType] = useState(0);
    const [trunkValue, setTrunkValue] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [companyList, setcompanyList] = useState([]);
    const [trunkIdList, setTrunkIdList] = useState([]);
    const [outboundTrunkIdList, setOutboundTrunkIdList] = useState([]);    
    const [popupOpen, setPopupOpen] = useState(false); 
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();        
        getUserAccess();
    }, []);
    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid]);    
    useEffect(() => {
        // if(access.length > 0 && token){
        //     checkAccess(access, token);
        // }
        if(token){
            getCustomerNameList();                   
        }
        
    }, [access, token]);
    // useEffect(() => {
    //     getResourceState("");
    // }, []);
    
    useEffect(() => {

        if(selectedValue){
            getTrunkIdList();
            getOutboundTrunkIdList();
        }
    }, [selectedValue, role]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            setSelectedValue(decoded.cid);
            setRole(decoded.role);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {        
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setSelectedValue(decoded.cid);
            setRole(decoded.role);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

   
    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
    
            
            if(response.data.report_switch !== 1){ 
                navigate('/dashboard');
            } 

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
 
    // const getResourceState = async (trunk) => {
    //     setPopupOpen(true);
    //     const response = await axiosJWT.get(`${baseUrl}/resources-analytics?trunk=${trunk}&cid=${selectedValue}`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });        
    //     setPopupOpen(false);        
    //     setInboundList(response.data.inbound);
    //     setOutboundList(response.data.outbound);        
    // }

    const getResourceState = (trunk) => {
        setPopupOpen(true);
        setInboundList([]);
        setOutboundList([]);
        const evtSource = new EventSource(`${baseUrl}/resources-analytics?trunk=${trunk}&cid=${selectedValue}&token=Bearer ${token}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        evtSource.onopen = () => {
            console.log('SSE connection established');
            // setPopupOpen(true);
        };

        evtSource.onmessage = function(event) {
            setPopupOpen(false);
            const data = JSON.parse(event.data);
            
            setInboundList(prevInboundList => [...prevInboundList, ...data.inbound]);
            setOutboundList(prevOutboundList => [...prevOutboundList, ...data.outbound]);
        };

        evtSource.onerror = function(error) {
            console.error('EventSource failed:', error);
            evtSource.close();
            // setPopupOpen(false);
            // reject(error); // Reject the promise on error
        };

        evtSource.onclose = () => {
            console.log('SSE connection closed');
            // setPopupOpen(false);
            // resolve(); // Resolve the promise when the connection is closed
        };

        return () => {
            evtSource.close();
        };
    }

    const getTrunkIdList = async () => {
        // setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/user-company/${selectedValue}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // setPopupOpen(false);        
        setTrunkIdList(response.data.user_white_label);
    }
    const getOutboundTrunkIdList = async () => {
        // setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/outbound-trunk-list/${selectedValue}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
            
        setOutboundTrunkIdList(response.data);
    }
 

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            // console.log(response.data.results);
            setAccess(response.data.results);
            setIsLoading(false);
            
        } catch (error) {
           console.log(error);
           setIsLoading(false);    
        }
    }

    const getCustomerNameList = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);
            const allArray = response.data.filter(item => item.id !== 1);
            console.log(allArray);
            // setApiResponseCount((prevCount) => prevCount + 1);
            setcompanyList(allArray);
           
           
            
            
          
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
    // const hasPermission = (permission) => {
       
    //     return access.some((item) => item.access === permission);
    //   };
         
    // const checkAccess = async (access) => {
        
    // }
    const handleBoundSearch = async(type, e) => {
        e.preventDefault();
        setBoundType(type); 
        setTrunkValue('');       
        // getResourceState("");  
        
    };


    // const handleSearchChange = (e) => {
    //     setSearchValue(e.target.value);
    // };
    const handleSearchSubmit = () => {
        setPage(1);
        getResourceState(trunkValue);
    };

    const clearFilter = () => {
        
        setTrunkValue('');       
        getResourceState("");
    }

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);                
    };
    const handleTrunkSearch = (e) => {
        setTrunkValue(e.target.value);
    }
 
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                
            <div className="content-page admin-attendance-page">               
                <div className="row">
                    <div className="col-8">
                       
                        <div className="left-title">
                            <h1>Resource Statistics</h1>
                            <span className={"bound-type " +(boundType === 0 ? 'bound-active' : '')}>[<a onClick={(e) => handleBoundSearch(0, e)} >Inbound</a>]</span>
                            <span className={"bound-type " +(boundType === 1 ? 'bound-active' : '')}>[<a onClick={(e) => handleBoundSearch(1, e)}>Outbound</a>]</span>
                        </div>
                    </div>
                    <div className="col-4 top-right-buttons">                                        
                        <div className="export-btn addnew-button"><Link to="/traffic-and-analytics-reporting"><span>&larr;</span> Back</Link></div>
                    </div>
                    
                </div>
                        
                <div className="fullrow">
                {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            
        </div>}
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                            <div className="boxinput">
                            
                            {boundType == 0 && 
                                <select name="" id=""  value={trunkValue} onChange={(e) => handleTrunkSearch(e)}>
                                    <option value="">Select Trunk</option>                        
                                    {role <= 2  && trunkIdList.map((trunk) => (                                                                    
                                        <option key={trunk.trunkId} value={trunk.trunkId}>
                                            {trunk.trunkId}
                                        </option>  
                                    ))}
                                    {role > 2  && trunkIdList.map((trunk) => (                                                                    
                                        <option key={trunk.trunkId} value={trunk.trunkId}>
                                            {trunk.customer_name}
                                        </option>  
                                    ))}
                                </select>
                            }

                            {boundType == 1 && 
                                <select name="" id="" value={trunkValue} onChange={(e) => handleTrunkSearch(e)}>
                                    <option value="">Select Trunk</option>                        
                                    {role <= 2  && outboundTrunkIdList.map((trunk) => (                                                                    
                                        <option key={trunk.value} value={trunk.value}>
                                            {trunk.value}
                                        </option>  
                                    ))}
                                    {role > 2  && outboundTrunkIdList.map((trunk) => (                                                                    
                                        <option key={trunk.value} value={trunk.value}>
                                            {trunk.customer_name}
                                        </option>  
                                    ))}
                                </select>
                            }
                      
                                
                               
                                <button  className="search-btn resource-stat-search" onClick={handleSearchSubmit}>Search</button>
                               { (trunkValue) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                <table className='resource-stats'>
                                    <thead>
                                    <tr>
                                        {role > 2 && <th rowSpan={2}>Customer Name</th>}     
                                        {role <= 2 &&<th rowSpan={2}>TID</th>  } 
                                        <th rowSpan={2}>Sansay</th>                                                                                                              
                                        <th colSpan={5}>15 Min</th>
                                        <th colSpan={5}>1 hr</th>
                                        <th colSpan={5}>24 hr</th>
                                    </tr>
                                    <tr>
                                        
                                        <th>CA</th>
                                        <th>ASR</th>
                                        <th>FCR</th>
                                        <th>ACD</th>                                        
                                        <th>APDD</th>
                                        <th>CA</th>
                                        <th>ASR</th>
                                        <th>FCR</th>
                                        <th>ACD</th>                                        
                                        <th>APDD</th>
                                        <th>CA</th>
                                        <th>ASR</th>
                                        <th>FCR</th>
                                        <th>ACD</th>                                        
                                        <th>APDD</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {boundType === 0 && inboundList.map((user, index) => (
                                        <tr key={user.id}>
                                        {role > 2 && <td>{user.customer_name}</td>}
                                        {role <= 2 && <td>{user.trunk_id}</td>} 
                                        <td>{user.sansay_name}</td>                                   
                                        <td>{user['1st15mins_call_answer']}</td>
                                        <td>{user['1st15mins_call_attempt']}</td>
                                        <td>{user['1st15mins_call_fail']}</td>
                                        <td>{user['1st15mins_call_durationSec']}</td>
                                        <td>{user['1st15mins_pdd_ms']}</td>
                                        <td>{user['1h_call_answer']}</td>
                                        <td>{user['1h_call_attempt']}</td>
                                        <td>{user['1h_call_fail']}</td>
                                        <td>{user['1h_call_durationSec']}</td>
                                        <td>{user['1h_pdd_ms']}</td>
                                        <td>{user['24h_call_answer']}</td>
                                        <td>{user['24h_call_attempt']}</td>
                                        <td>{user['24h_call_fail']}</td>
                                        <td>{user['24h_call_durationSec']}</td>
                                        <td>{user['24h_pdd_ms']}</td>
                                      
                                     
                                        
                                    </tr>
                                    ))}
                                    {boundType === 1 && outboundList.map((user, index) => (
                                        <tr key={user.id}>
                                        {role > 2 && <td>{user.customer_name}</td>}
                                        {role <= 2 && <td>{user.trunk_id}</td>}       
                                        <td>{user.sansay_name}</td>
                                        <td>{user['1st15mins_call_answer']}</td>
                                        <td>{user['1st15mins_call_attempt']}</td>
                                        <td>{user['1st15mins_call_fail']}</td>
                                        <td>{user['1st15mins_call_durationSec']}</td>
                                        <td>{user['1st15mins_pdd_ms']}</td>
                                        <td>{user['1h_call_answer']}</td>
                                        <td>{user['1h_call_attempt']}</td>
                                        <td>{user['1h_call_fail']}</td>
                                        <td>{user['1h_call_durationSec']}</td>
                                        <td>{user['1h_pdd_ms']}</td>
                                        <td>{user['24h_call_answer']}</td>
                                        <td>{user['24h_call_attempt']}</td>
                                        <td>{user['24h_call_fail']}</td>
                                        <td>{user['24h_call_durationSec']}</td>
                                        <td>{user['24h_pdd_ms']}</td>
                                      
                                     
                                        
                                    </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </div>
    )
}
 
export default TrafficAnalyticsReporting