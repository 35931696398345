/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';

const Step2 = ({ onBack, onNext, cid,role,token, formData, setFormData  }) => {
    
    const [tnNumberRouteList, setTnNumberRouteList] = useState(
        formData.reserveNumberList.map((item) => ({
            ...item,
            routeOption: '', // Adding the routeOption key with a blank value
        }))
    );
    // const [inputValue, setInputValue] = useState('');
    const [routingList, setRoutingList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [orderNumberType, setOrderNumberType] = useState(0);
    const [selectedRoutingOption, setSelectedRoutingOption] = useState('');
    const [trunkIdCount, setTrunkIdCount] = useState(false);
    const [routeSwitch, setRouteSwitch] = useState(0);
    const [carrierTrunkCount, setCarrierTrunkCount] = useState(false);
    const [allCarrierSTrunkCount, setAllCarrierSTrunkCount] = useState(false);
    const [selectedRoutingOptionName, setSelectedRoutingOptionName] = useState('');
    const [selectedRoutingOptionId, setSelectedRoutingOptionId] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [assignedRoutingOptions, setAssignedRoutingOptions] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showIntruction, setShowIntruction] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState({}); 
    const [trunkRouteName, setTrunkRouteName] = useState([]); 
    const [trunkRouteList, setTrunkRouteList] = useState([]);   
    const [nullMessge, setNullMessge] = useState(false);
    
    useEffect(() => {
        // Make the API call when the component mounts
        getRoutingOption();
        getTrunkName();
    }, [formData.reserveNumberList]);
   
   

    const getRoutingOption = async () => {
        try {
            
        
            let compId;
            if(role <= 2){
                compId = formData.selectedCompany;
                console.log(formData.selectedCompany);
           
            }
            else{
                compId = cid;
            }
            
            const counts = formData.reserveNumberList.reduce((acc, curr) => {
                acc[curr.orderNumberType] = (acc[curr.orderNumberType] || 0) + 1;
                return acc;
            }, {});
            const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/route-list/${compId}?type=${result}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data;
            setLoading(false); 
        if(allArray.statusCode == 200){
            setRouteSwitch(allArray.switch);
            setRoutingList(allArray.data);
            setRouteList(allArray.data);
            // if(allArray.data.length === 1){
            //     setTrunkIdCount(true);
            //     setSelectedRoutingOption(allArray.data[0].value);
            //     setSelectedRoutingOptionName(allArray.data[0].name);
            //     setSelectedRoutingOptionId(allArray.data[0].id);
            //     const e = new Event("click");
            //     handleSelectAll(e);
               
                
               
            // }else{
                
            //     setTrunkIdCount(false);
            // }
        }
        setPopupOpen(false);
          
        } catch (error) {
            setLoading(false);
            setPopupOpen(false);
           console.log(error);
            
        }
    };
    useEffect(() => {

        if(routeList.length === 1){
            setTrunkIdCount(true);
            setSelectedRoutingOption(routeList[0].value);
            setSelectedRoutingOptionName(routeList[0].name);
            setSelectedRoutingOptionId(routeList[0].id);
            const e = new Event("click");
            handleSelectAll(e);
        }else{
            setTrunkIdCount(false);
        }
    }, [routeList]);

    useEffect(() => {
        const counts = formData.reserveNumberList.reduce((acc, curr) => {
            acc[curr.orderNumberType] = (acc[curr.orderNumberType] || 0) + 1;
            return acc;
        }, {});
        const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
        setOrderNumberType(result);
    }, [formData.reserveNumberList]);
   
    const [tableData, setTableData] = useState( [
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
          selectedOption: '',
          inputText: '',
          yesNo: '',
          
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
            
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        },
        {
            selectedOption: '',
            inputText: '',
            yesNo: '',
           
        }
    ]);
    const getTrunkName = async () => {
        let compId;
            if(role <= 2){
                compId = formData.selectedCompany;
                console.log(formData.selectedCompany);
            //     const response = await axios.post(`${baseUrl}/routing-option-list`);
            //     const allArray = response.data;
            
            // setLoading(false); 
            // if(allArray.statusCode == 200){
            //     setRoutingList(allArray.routingOptionList)
            // }
            }
            else{
                compId = cid;
                setFormData({ ...formData, selectedCompany: cid });
            }
            const counts = formData.reserveNumberList && formData.reserveNumberList.reduce((acc, curr) => {
                acc[curr.orderNumberType] = (acc[curr.orderNumberType] || 0) + 1;
                return acc;
            }, {});
            const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;
        const response = await axios.get(`${baseUrl}/get-trunk-name/${compId}?type=${result}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }       
        });
        const allArray = response.data;
        // console.log(allArray.data[0].value);
        setTrunkRouteName(allArray.data);
        setTrunkRouteList(allArray.data);
        
        // console.log(allArray);
    }

    useEffect(() => {
        if(selectedRows.length){
           
            const filteredData = formData.reserveNumberList.filter(obj => selectedRows.includes(obj.telephoneNumber));
            const counts = filteredData.reduce((acc, curr) => {
                acc[curr.orderNumberType] = (acc[curr.orderNumberType] || 0) + 1;
                return acc;
            }, {});
            const result = counts[0] && counts[1] ? 2 : counts[0] ? 0 : 1;

            const updateRoute = routeList.filter((obj) => {
                if(result == 0){
                    return obj.did_access == 1;
                }else if(result == 1){
                    return obj.toll_free_access == 1;
                }else if(result == 2){
                    return obj.did_access == 1 && obj.toll_free_access == 1;
                }
            });

            setRoutingList(updateRoute);
 
        }
        
    }, [selectedRows, formData.reserveNumberList, routeList]);
  
   
    useEffect(() => {
        if(routingList.length > 0 && formData.reserveNumberList){
       
        const hasNullValue = routingList.some(obj => obj.bandwidth_did === null || obj.bandwidth_toll_free === null);
            const checkNumberType = formData.reserveNumberList.some(item => item.numberType == 1);
            if(hasNullValue && checkNumberType){
                setNullMessge(true);
            }else{
                setNullMessge(false);
            }
        }
    }, [formData.reserveNumberList, routingList])
    //  If single trunk then direct assign
    useEffect(() => {

        if( trunkIdCount && trunkRouteName.length > 0){
            console.log("default pass");
            handleDefaultSet();
        }else{
            console.log("default not pass");
        }
    },[trunkRouteName, trunkIdCount])
    
   
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [routeOptionDisabled, setRouteOptionDisabled] = useState(false);

    const handleToggleSelect = ( phoneNumber) => {
        const updatedSelectedRows = selectedRows.includes(phoneNumber)
        ? selectedRows.filter((num) => num !== phoneNumber)
        : [...selectedRows, phoneNumber];
    
        if(updatedSelectedRows.length > 0){
            setRouteOptionDisabled(true);
        }else{
            setRouteOptionDisabled(false);
        }
        setSelectedRows(updatedSelectedRows);
        
      };

    const handleSelectAll = (e) => {
        e.preventDefault();
        // if (selectAll) {
        //     setSelectedRows([]);
        // } else {
        //     setSelectedRows(formData.reserveNumberList.map(item => item.telephoneNumber));
        // }
        // setSelectAll(!selectAll);
        let selectRows = [];
        if(routeList[0].did_access == 1 && routeList[0].toll_free_access == 2){
            selectRows = formData.reserveNumberList.filter(item => item.orderNumberType === 0).map(item => item.telephoneNumber);
        }else if(routeList[0].toll_free_access == 1 && routeList[0].did_access == 2){
            selectRows = formData.reserveNumberList.filter(item => item.orderNumberType === 1).map(item => item.telephoneNumber);
        }else{
            selectRows = formData.reserveNumberList.map(item => item.telephoneNumber)
        }
        console.log(selectRows, "selectRows");
        setSelectedRows(selectRows);
        
    };

    const handleSwitchSelectAll = () => {
        // e.preventDefault();
        if (selectAll) {
            setSelectedRows([]);
            setRouteOptionDisabled(false);
        } else {
            setSelectedRows(formData.reserveNumberList.map(item => item.telephoneNumber));
            setRouteOptionDisabled(true);
        }
       
        setSelectAll(!selectAll);
       
        
    };


   

    const handleAssignRoutingOption = (e) => {
        e.preventDefault();

        
        if (!selectedRoutingOption) {
            alert("Please select routing option")
            // Show a validation error, routing option not selected
            return;
        }
        if (selectedRows.length === 0) {
            alert("Please select at least one row")
            // Show a validation error, no rows selected
            return;
        }
        console.log(selectedRows);
        const selectedItemName = selectedRoutingOptionName;
        const selectedItemId = selectedRoutingOptionId;
        
        const updatedAssignedRoutingOptions = { ...assignedRoutingOptions };
        selectedRows.forEach((telephoneNumber) => {
            updatedAssignedRoutingOptions[telephoneNumber] = selectedRoutingOption;
        });
        setAssignedRoutingOptions(updatedAssignedRoutingOptions);
        const sourceArray = formData.tnNumberRouteList || tnNumberRouteList;
        formData.reserveNumberList.forEach(reserveEntry => {
            // Find the corresponding entry in sourceArray
            const correspondingEntry = sourceArray.find(entry => entry.telephoneNumber === reserveEntry.telephoneNumber);
        
            // If there is no corresponding entry, add a new entry to sourceArray
            if (!correspondingEntry) {
                sourceArray.push({
                    ...reserveEntry,
                    "routeOption": "",
                    "routeOptionName": "",
                    "routeOptionId": ""
                });
            }
        });
        // console.log(sourceArray);
        const updatedNewReserveNumberList = sourceArray.map(item => {
            
            if (selectedRows.includes(item.telephoneNumber)) {
                return {
                    ...item,
                    routeOption: selectedRoutingOption,
                    routeOptionName: selectedItemName,
                    routeOptionId:selectedItemId
                };
            }
            return item;
        });
          
    
        setTnNumberRouteList(updatedNewReserveNumberList);
        setFormData({ ...formData, tnNumberRouteList: updatedNewReserveNumberList });
        
    };

    const toggleInstrunction = () => {
        setShowIntruction(!showIntruction);
    };
    
    const handleRemoveSelected = async(e, item) => {
        e.preventDefault();
        
        const updatedRoutePath = removeItemByTelephoneNumber(formData, item.telephoneNumber);
        const updateTnRoutePath = removeTnRoutePathNumber(formData, item.telephoneNumber)
        const updatedList = formData.reserveNumberList.filter(tnObj => tnObj.telephoneNumber !== item.telephoneNumber);
        
        const updateData = { ...formData, reserveNumberList: updatedList, routePath: updatedRoutePath, tnNumberRouteList : updateTnRoutePath };
        
        setFormData(updateData);
        setTnNumberRouteList(updatedList);
    };
    const removeItemByTelephoneNumber = (formData, telephoneNumber) => {
        return formData.routePath.filter(obj => {
            const key = Object.keys(obj)[0]; // Extract the key (telephone number)
            return key !== telephoneNumber; // Keep objects where the key does not match the given telephone number
        });
        
    };
    
    const removeTnRoutePathNumber = (formData, telephoneNumber) => {
        if(formData.tnNumberRouteList){
            return formData.tnNumberRouteList.filter(obj => obj.telephoneNumber !== telephoneNumber);
        }else{
            return [];
        }
    }
    const [dataArray, setDataArray] = useState(
        formData.routePath ? formData.routePath : []
      );

    //  Switch off and set rout path as per selected route option
    useEffect(() => {
        const routeDataLength = (formData.routePath ? formData.routePath.length : 0) >= (formData.reserveNumberList.length - 1) ? true : false;
        console.log(routeDataLength);
        
        console.log(formData);
        if( Object.keys(assignedRoutingOptions).length > 0 && trunkRouteList.length > 0 && routeSwitch == 0){
            const updatedArray = [];
            formData.reserveNumberList.forEach((item) => {               
                if (item.telephoneNumber in assignedRoutingOptions) {
                    // Get the corresponding value from assignedRoutingOptions
                    const value = assignedRoutingOptions[item.telephoneNumber];
                    
                    // Find the matching entry in trunkRouteName
                    const matchingEntry = trunkRouteList.find(entry => entry.name === value);
                    
                    if (matchingEntry) {
                        
                        const newTableData = tableData.map((tableItem, index) => {
                            if (index === 0) {
                                return {
                                    ...tableItem,
                                    selectedOption: matchingEntry.value.toString(),
                                    inputText: '',
                                    yesNo: '1',
                                };
                            }
                            return tableItem;
                        });
                  
                        // Create newData with the updated tableData
                        const newData = {
                            policy: "1",
                            tableData: newTableData,
                        };
                        const objectToAdd = {};
                        objectToAdd[item.telephoneNumber] = {...newData};

                        updatedArray.push(objectToAdd);
                       
                    }
                }     
            });
            // console.log(updatedArray);
            
            // setDataArray(updatedArray);
            setDataArray(prevDataArray => {
                const newDataArray = [...prevDataArray];
                
                updatedArray.forEach(updatedItem => {
                  const key = Object.keys(updatedItem)[0];
                  const existingIndex = newDataArray.findIndex(item => Object.keys(item)[0] === key);
              
                  if (existingIndex !== -1) {
                    // If key exists, update the existing item
                    newDataArray[existingIndex][key] = updatedItem[key];
                  } else {
                    // If key doesn't exist, add a new item
                    newDataArray.push(updatedItem);
                  }
                });
              
                return newDataArray;
              });
            // setFormData({ ...formData, routePath: updatedArray });
            setFormData(prevFormData => {
                const existingRoutePath = Array.isArray(prevFormData.routePath) ? prevFormData.routePath : [];
                const newRoutePath = existingRoutePath.map(existingItem => {
                  const key = Object.keys(existingItem)[0];
                  const updatedItem = updatedArray.find(item => Object.keys(item)[0] === key);
              
                  return updatedItem ? { [key]: updatedItem[key] } : existingItem;
                });
              
                // Add new items from updatedArray that don't exist in existingRoutePath
                updatedArray.forEach(updatedItem => {
                  const key = Object.keys(updatedItem)[0];
                  if (!newRoutePath.some(existingItem => Object.keys(existingItem)[0] === key)) {
                    newRoutePath.push(updatedItem);
                  }
                });
              
                return { ...prevFormData, routePath: newRoutePath };
              });
              

        
        }
        if(Object.keys(assignedRoutingOptions).length > 0 && trunkRouteList.length > 0 && routeSwitch == 1){
            const updatedArray = [];
            formData.reserveNumberList.forEach((item) => {               
                if (item.telephoneNumber in assignedRoutingOptions) {
                    // Get the corresponding value from assignedRoutingOptions
                    const value = assignedRoutingOptions[item.telephoneNumber];
                    
                    // Find the matching entry in trunkRouteName
                    const matchingEntry = trunkRouteList.find(entry => entry.name === value);
                    
                    if (matchingEntry) {
                        
                        const newTableData = tableData.map((tableItem, index) => {
                            if (index === 0) {
                                return {
                                    ...tableItem,
                                    selectedOption: matchingEntry.value.toString(),
                                    inputText: '',
                                    yesNo: '1',
                                };
                            }
                            return tableItem;
                        });
                  
                        // Create newData with the updated tableData
                        const newData = {
                            policy: "1",
                            tableData: newTableData,
                        };
                        const objectToAdd = {};
                        objectToAdd[item.telephoneNumber] = {...newData};

                        updatedArray.push(objectToAdd);
                       
                    }
                }     
            }); 
            setDataArray(prevDataArray => {
                const newDataArray = [...prevDataArray];
                
                updatedArray.forEach(updatedItem => {
                  const key = Object.keys(updatedItem)[0];
                  const existingIndex = newDataArray.findIndex(item => Object.keys(item)[0] === key);
              
                  if (existingIndex !== -1) {
                    // If key exists, update the existing item
                    newDataArray[existingIndex][key] = updatedItem[key];
                  } else {
                    // If key doesn't exist, add a new item
                    newDataArray.push(updatedItem);
                  }
                });
              
                return newDataArray;
              });
        }
        
    },[formData.reserveNumberList, routeSwitch, assignedRoutingOptions]);

    
    const handleNext = (e) => {
        e.preventDefault();
       console.log(formData);
        const hasBlankRouteOption = formData.tnNumberRouteList.some(item => item.routeOption === '');
        const hasBlankRouteOptionInFormData = formData.tnNumberRouteList ? formData.tnNumberRouteList.some(item => item.routeOption === '') : true;
        const errors = validateFormData(formData);
        console.log(errors);
        const bandwidthList = tnNumberRouteList.filter(obj => obj.numberType === 1);
        const firstObj = bandwidthList[0];
        const areAllBandwidthRouteSame = bandwidthList.every(obj => obj.routeOption === firstObj.routeOption && obj.routeOptionId === firstObj.routeOptionId);
        console.log(tnNumberRouteList)
        if (hasBlankRouteOptionInFormData) {
            if(tnNumberRouteList.length <= 0){
                alert("Please Order at least one number");
            }
            if (hasBlankRouteOption){
                alert("Please assign routing options for all selected rows");
            }
            
        }else if(nullMessge){
            alert("There is an issue with the customer configuration which prevents ordering new numbers.  Please contact Broadband Dynamics to have this resolved.");
        }else if (errors.length > 0){
            alert(errors.join('\n'));
            // 
        }else if(!areAllBandwidthRouteSame){
            alert("Due to carrier restrictions, multiple routing options are not allowed on this order.  If you require numbers to have different routing options, please place an order for each set of numbers requiring different routing.")
        }else{
            if(formData.routePath.length != formData.reserveNumberList.length){
                alert("Please assign routing options for all selected rows");
            }else{

            const filteredRoutePath = formData.routePath.filter((entry) => {
                const telephoneNumber = Object.keys(entry)[0]; // Extract the telephone number.
              
                // Check if the extracted telephone number is in tnreservelist
                return formData.reserveNumberList.some((item) => item.telephoneNumber === telephoneNumber);
            });
              


            const filteredTnnumberlist = formData.tnNumberRouteList.filter(item =>
                formData.reserveNumberList.some(reserveItem => reserveItem.telephoneNumber === item.telephoneNumber)
            );
          console.log(formData.tnNumberRouteList);
            setFormData({ ...formData, tnNumberRouteList: filteredTnnumberlist, routePath: filteredRoutePath });
            onNext();
            }
            
        }
    
    };

    const openModal = (item) => {
        console.log(formData);
        setShowModal(true);
        setCurrentItem(item);
    };
    
    const closeModal = () => {
        setShowModal(false);
        setValidationErrors([]);
    };

    
    
    useEffect(() => {
        // Make the API call when the component mounts
      console.log(tableData);
    }, [tableData]);
    const handlePolicyChange = (event) => {
        setSelectedPolicy(event.target.value);
    };
    
    const handleRowChange = (e, index, fieldName) => {
        const newValue = e.target.value;
        // const newValue = JSON.parse(e.target.value); // Parse the JSON string
        console.log(newValue); // Access the id
      
        
        setTableData((prevData) => {
            const updatedData = prevData.map((row, rowIndex) => {
                if (rowIndex === index) {
                    // Update the field for the specific row
                    return {
                        ...row,
                        [fieldName]: newValue
                      
                    };
                }
                // Return other rows as they are
                return row;
            });
            return updatedData;
        });
    };
    // const [dataArray, setDataArray] = useState([]);
   
      
      const [validationErrors, setValidationErrors] = useState([]);
      const [selectedMobile, setSelectedMobile] = useState(null); 
     
      useEffect(() => {
        if(!trunkIdCount && selectedRows.length > 0 && routeSwitch == 1 && validationErrors.length == 0){
            const e = new Event("click");
            handleAssignRoutingOption(e);
        }
        
       }, [selectedRows, trunkIdCount, routeSwitch, validationErrors]);
       
      useEffect(() => {
        // Validate formData.routePath whenever it changes
        if (formData.routePath && formData.routePath.length > 0) {
            // console.log(formData.routePath);
            // console.log(formData.reserveNumberList);
            const errors = validateFormData(formData);
             setValidationErrors(errors);
            //  console.log(errors);
            //  console.log(formData.routePath);
        //  console.log(errors.filter(error => error.includes(selectedMobile)));
      if (selectedMobile) {
        
        const filteredErrorsArray = errors.filter(error => error.includes(selectedMobile));
        setValidationErrors(filteredErrorsArray);
        
        setShowModal(filteredErrorsArray.length > 0);
      }
       else {
        
        setValidationErrors(errors);
        setShowModal(errors.length > 0);
        
      }
          }
      }, [formData.routePath, selectedMobile]);

      useEffect(() => {
        if(currentItem){
           
            const result = currentItem.orderNumberType;

            const updateTrunk = trunkRouteList.filter((obj) => {
                if(result == 0){
                    return obj.did_access == 1;
                }else if(result == 1){
                    return obj.toll_free_access == 1;
                }else if(result == 2){
                    return obj.did_access == 1 && obj.toll_free_access == 1;
                }
            });
            
            const updateRouteList = routeList.filter((obj) => {
                if(result == 0){
                    return obj.did_access == 1;
                }else if(result == 1){
                    return obj.toll_free_access == 1;
                }else if(result == 2){
                    return obj.did_access == 1 && obj.toll_free_access == 1;
                }
            });
            setTrunkRouteName(updateTrunk);
            setRoutingList(updateRouteList);
 
        }
    }, [currentItem, formData.reserveNumberList, trunkRouteList,routeList]);

    // single number trunks set
    const handleSave = () => {
        
       
        const newData = {
            [currentItem.telephoneNumber]: { policy: selectedPolicy, tableData: tableData },
        };

        // Add the object to the dataArray
        const indexToUpdate = dataArray.findIndex(
            (data) => data.hasOwnProperty(currentItem.telephoneNumber)
        );

        // If an object with the same telephone number exists, update it
        if (indexToUpdate !== -1) {
            const updatedArray = [...dataArray];
            updatedArray[indexToUpdate] = newData;
            setDataArray(updatedArray);
            setFormData({ ...formData, routePath: updatedArray });
        } else {
            // If it doesn't exist, add the new data
            // setDataArray((prevDataArray) => [...prevDataArray, newData]);
            setDataArray((prevDataArray) => {
                const updatedArray = [...prevDataArray, newData];
                setFormData({ ...formData, routePath: updatedArray });
                return updatedArray;
              });
        }
        setSelectedMobile(currentItem.telephoneNumber);
        if(!trunkIdCount){
            // console.log("ffdfsds");
            setCarrierTrunkCount(true);
            setAllCarrierSTrunkCount(false);
          }else{
            setCarrierTrunkCount(false);
            setAllCarrierSTrunkCount(false);
          }
    };

  // all number trunks set
    const handleDefaultSet = () => {
        
       
        // const newData = {
        //     policy: selectedPolicy,
        //     tableData: tableData,
        // };
        let newData;
        if(!trunkIdCount){
            const confirmed = window.confirm("Are you sure you want to apply this to all?");
            if(confirmed){
                newData = {
                    policy: selectedPolicy,
                    tableData: tableData,
                };
                // Iterate through the reserveNumberList and set the data for each telephone number
                const updatedArray = formData.reserveNumberList.map((item) => ({
                    [item.telephoneNumber]: newData,

                }));

                // Update the formData with the updatedArray
                setFormData({ ...formData, routePath: updatedArray });

                // You can also update dataArray if needed
                setDataArray(updatedArray);
                setSelectedMobile(null);
                // Close the modal or perform other actions        
                if(!trunkIdCount){
                  // console.log("ffdfsds");
                  setAllCarrierSTrunkCount(true);
                  setCarrierTrunkCount(true);
                }else{
                  setAllCarrierSTrunkCount(false);
                  setCarrierTrunkCount(true);
                }
            }
            
        }else{
            
            const newTableData = [...tableData];

            // Check if the array has at least one element
            if (newTableData.length > 0) {
              // Set the value of the first element
              newTableData[0].selectedOption = trunkRouteName[0].value; // Replace "new value" with your desired value
              newTableData[0].inputText = '';
              newTableData[0].yesNo = '1';
            }
            newData = {
                policy: "1",
                tableData: tableData,
            };

            // Iterate through the reserveNumberList and set the data for each telephone number
            const updatedArray = formData.reserveNumberList.map((item) => ({
                [item.telephoneNumber]: newData,

            }));
        
            // Update the formData with the updatedArray
            setFormData({ ...formData, routePath: updatedArray });

            // You can also update dataArray if needed
            setDataArray(updatedArray);
            setSelectedMobile(null);
            // Close the modal or perform other actions
            if(!trunkIdCount){
              // console.log("ffdfsds");
              setAllCarrierSTrunkCount(true);
              setCarrierTrunkCount(true);
            }else{
              setAllCarrierSTrunkCount(false);
              setCarrierTrunkCount(true);
            }
        }
        
        
        
    }
    

    const handleUnassignedSet = () => {
        const confirmed = window.confirm("Are you sure you want to apply this to all Unassigen numbers?");
        if(confirmed){
            const newData = {
                policy: selectedPolicy,
                tableData: tableData,
            };

            let updatedArray;
            if(formData.routePath){
                const routePathKeys = formData.routePath.map(obj => Object.keys(obj)).flat();
                console.log(routePathKeys);
                const UnassignedArray = formData.reserveNumberList.filter((item) => {
                    return !routePathKeys.includes(item.telephoneNumber);
                });
                console.log(UnassignedArray);
                updatedArray = UnassignedArray.map((item) => ({
                    [item.telephoneNumber]: newData,
                }));
                // console.log(updatedArray);
            }else{
                
                 updatedArray = formData.reserveNumberList.map((item) => ({
                    [item.telephoneNumber]: newData,
    
                }));
                
            }
           
            if(updatedArray && updatedArray.length > 0){
                // Update the formData with the updatedArray
                setFormData(prevData => ({
                    ...prevData,
                    routePath: [...prevData.routePath, ...updatedArray],
                  }));

                // You can also update dataArray if needed
                setDataArray(updatedArray);
                setSelectedMobile(null);
                // Close the modal or perform other actions
                if(!trunkIdCount){
                    // console.log("ffdfsds");
                    setAllCarrierSTrunkCount(true);
                    setCarrierTrunkCount(true);
                }else{
                    setAllCarrierSTrunkCount(false);
                    setCarrierTrunkCount(true);
                }
            }
            
        
           
        }
    }

    // trunks validation for all number
    const validateFormData = (data) => {
        const errors = [];
        console.log(data);
        // Your validation logic here
        if (!data.routePath) {
            // errors.push("Route path is missing.");
            errors.push(`Please Fill proper trunk value for all number`);
        } else {
          data.routePath.forEach(dataItem => {
            const mobileKey = Object.keys(dataItem)[0]; // Get the telephoneNumber from the object key
            const currentMobile = dataItem[mobileKey];
    
            if (!currentMobile) {
                errors.push(`Mobile ${mobileKey} not found in the data.`);
                // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
            } else {
              if (!currentMobile.policy) {
                errors.push(`Policy field is required for ${mobileKey}.`);
                // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
              }
    
            
              const hasTableDataValue = currentMobile.tableData.some(tableItem =>
                tableItem.selectedOption 
              );
              const isValid = currentMobile.tableData.some((tableItem, index) => {
                if (index < currentMobile.tableData.length - 1) {
                    return tableItem.selectedOption === "" && currentMobile.tableData[index + 1].selectedOption !== "";
                }
                return false;
            });
                // console.log(hasTableDataValue)
                
              if (!hasTableDataValue) {
                errors.push(`Atleast one route is required for ${mobileKey}.`);
                // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
              } else if(isValid) {
                errors.push(`Please select proper trunk for ${mobileKey}.`);
              }else if (currentMobile.policy === "2") {
                const inputTextValues = currentMobile.tableData.map(tableItem => Number(tableItem.inputText));
                const hasUserInput = inputTextValues.some(value => value > 0);
    
                if (!hasUserInput) {
                    errors.push(`Percentage values for ${mobileKey} are required.`);
                    // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
                } else {
                  const sumOfInputTextValues = inputTextValues.reduce((acc, value) => acc + value, 0);
    
                  if (sumOfInputTextValues !== 100) {
                    errors.push(`Sum of percentage for ${mobileKey} is not equal to 100.`);
                    // errors.push(`Please Fill proper trunk value for ${mobileKey}`);
                  }
                }
              }
            }
          });
        }
    
        return errors;
      };


    useEffect(() => {
        if (formData.routePath && currentItem.telephoneNumber) {
            // Check if currentItem.telephoneNumber is in formData.routePath
            const foundItem = formData.routePath.find(
                (item) => Object.keys(item)[0] === currentItem.telephoneNumber
            );
    
            // Set selectedPolicy based on the check
            setSelectedPolicy(foundItem ? foundItem[currentItem.telephoneNumber].policy : '');
            setTableData(foundItem ? foundItem[currentItem.telephoneNumber].tableData : [
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                  selectedOption: '',
                  inputText: '',
                  yesNo: '',
                  
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                },
                {
                    selectedOption: '',
                    inputText: '',
                    yesNo: '',
                    
                }
            ]);
        }

    }, [formData.routePath, currentItem.telephoneNumber]);
      
    //  check trunks is valid or not as per nubmer
    useEffect(() => {
        
        formData.reserveNumberList.forEach(tn => {
            const phoneNumber = tn.telephoneNumber;
            const matchingRoute = formData.routePath && formData.routePath.find(route => route.hasOwnProperty(phoneNumber));
           
            if (!matchingRoute) {
              tn.validTrunk = false;
              return;
            }
        
            const routeData = matchingRoute[phoneNumber];
            
            if (routeData.policy == "1") {
                
              tn.validTrunk = routeData.tableData.some(item => item.selectedOption !== "");
            } else if (routeData.policy === "2") {
              const hasSelectedOption = routeData.tableData.some(item => item.selectedOption !== "");
              const hasPercentageValues = routeData.tableData.some(item => item.inputText !== "" && Number(item.inputText) > 0);
              const sumOfPercentageValues = routeData.tableData.reduce((sum, item) => sum + Number(item.inputText), 0);
                // console.log(hasSelectedOption);
                // console.log(hasPercentageValues);
                // console.log(sumOfPercentageValues);
              tn.validTrunk = hasSelectedOption && hasPercentageValues && sumOfPercentageValues === 100;
            } else {
                
              tn.validTrunk = false;
            }
          });
         
    },[formData.routePath, formData.reserveNumberList]);

    //  set all trunk as per selected route while switch is on
    useEffect(() => {      
        if(routeSwitch == 1 && !trunkIdCount && allCarrierSTrunkCount && formData.routePath && formData.routePath.length > 0){
            // console.log("dfsdfds");
            let firstMatchingRoute = null; 
            formData.routePath.forEach((entry) => {
                const telephoneNumber = Object.keys(entry)[0]; // Extract the telephone number.
                const targetValue =  entry[telephoneNumber].tableData[0].selectedOption;
            
                const selected = trunkRouteName.find((item) => item.value.includes(targetValue))?.name;
                const routeName =  routingList.filter((element) => element.value === selected);

                if (routeName) {
                    firstMatchingRoute = routeName;
                    if (firstMatchingRoute) {
                        return; // Exit the loop early if you found the first matching route
                    }
                }
            });

       
            setAllCarrierSTrunkCount(true);
            setCarrierTrunkCount(false);
       
            setSelectedRoutingOption(firstMatchingRoute[0].value);
            setSelectedRoutingOptionName(firstMatchingRoute[0].name);
            setSelectedRoutingOptionId(firstMatchingRoute[0].id);
        
            const e = new Event("click");
            handleSelectAll(e);

        }

        

      
    },[!allCarrierSTrunkCount, !trunkIdCount, formData.routePath, routeSwitch]);

    // set selected trunks as per selected rotue while swtich is on
    useEffect(() => {       
        if(routeSwitch == 1 && !trunkIdCount && carrierTrunkCount &&  formData.routePath && formData.routePath.length > 0 && selectedMobile){
            // console.log("dfsdfds");
            let firstMatchingRoute = null; 
             // Replace with your actual key

            const tableData = formData.routePath.find((item) => item[selectedMobile]?.tableData) ?. [selectedMobile]?.tableData || null;
            // console.log(tableData);
            if(tableData){
                // console.log(tableData);
                const targetValue =  tableData[0].selectedOption;
                const selected = trunkRouteName.find((item) => item.value.includes(targetValue))?.name;
                const routeName =  routingList.filter((element) => element.value === selected);
                // console.log(routeName);
                firstMatchingRoute = routeName;
     
           
                setCarrierTrunkCount(true);
                setAllCarrierSTrunkCount(false);
                setSelectedRoutingOption(firstMatchingRoute[0].value);
                setSelectedRoutingOptionName(firstMatchingRoute[0].name);
                setSelectedRoutingOptionId(firstMatchingRoute[0].id);
            
                // // const e = new Event("click");
                
                const updatedSelectedRows = selectedRows.includes(selectedMobile) ? selectedRows.filter((num) => num === selectedMobile) : [selectedMobile];
                // console.log(updatedSelectedRows);
                // console.log(selectedRows);
                setSelectedRows(updatedSelectedRows);
                
            }
        }

        
      
    },[!carrierTrunkCount, !trunkIdCount, formData.routePath,selectedMobile, routeSwitch]);



    useEffect(() => {
        
        if(trunkIdCount  && selectedRows.length > 0 ){
            const e = new Event("click");
            handleAssignRoutingOption(e);
        }
    }, [selectedRows, !trunkIdCount, carrierTrunkCount, allCarrierSTrunkCount, routeSwitch]);






    const [routeResults, setRouteResults] = useState([]);
    useEffect(() => {
        if(trunkRouteName.length > 1 && routeSwitch === 1 && formData.reserveNumberList && formData.routePath){
            // console.log(formData.reserveNumberList);
            // console.log(formData.routePath);
            const results = formData.reserveNumberList.map((item) => {
                const numberData = formData.routePath.find((items) => items[item.telephoneNumber]);
                console.log(numberData);
                if(numberData){
                     const idsToLookup = numberData[item.telephoneNumber].tableData.map((row) => row.selectedOption);
                    const result = trunkRouteName
                      .filter((item) => idsToLookup.includes(item.value))
                      .map((item) => item.name)
                      .join(', ');
                    return result;
                }else{
                    return '';
                }
                
              });
              setRouteResults(results);
              setFormData({ ...formData, routeResults: results });
        }
        
        
    },[routeSwitch, trunkRouteName, formData.reserveNumberList, formData.routePath]);


    return(
    <>
      <div className="row">
            <div className="col-6">
                <div className="left-title">
                    <h1>Order New Number </h1>
                    {/* <small>(Assign Routing Options Step 2)</small> */}
                </div>
            </div>
            <div className="col-6">

            </div>
        </div>
        {popupOpen && (
                     <Loader /> 
                ) }
        <div className="content-sub-title">
            <div className="row">
                <div className='order-queue'>
                <h2>Tel Numbers and Routing Options
                    <span><img src={`${process.env.PUBLIC_URL}/images/view.png`} onClick={toggleInstrunction} style={{ cursor: 'pointer' }} /></span>
                {/* <span >
                            {showIntruction ? '👁️' : '👁️'}
                        </span> */}
                </h2>
                <h3>Tel Numbers: {formData.reserveNumberList.length}</h3>
                </div>
            </div>
        </div>
        <div className="common-white-shadow-background setting-section tableinput-section">
        <div className="row footer-row">
            <div className="footer-row-left">
                <a href="#" onClick={onBack} className="Back"> <img src="images/back-arrow.png" />Back</a>
            </div>
            <div className="footer-row-right">
            <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                <a href="#" onClick={handleNext} className="next">Next</a>
            </div>
        </div>
        </div>
        {showIntruction && <div className="common-white-shadow-background setting-section">
            <div className="row">                
                <div className="introducebpx">
                    <p><strong>Instructions</strong></p>
                    <p>To assign a new Routing Option:1. Use checkbox to select desired telephone number. 2. Choose Routing Option from the "Routing Option" drop-down. 3. Click "Assign Routing Option".</p>
                    <p >To remove a number from the order:1. Click on the "X" icon for the telephone number you want to remove</p>
                    {/* <p>To remove a row:1. Use checkbox to select desired row. 2. Click "Remove Row".</p> */}
                    <p>To Assign Trunk routing details: 1. Click on the telephone icon 2. Select the Policy from the drop down. 3. Select a Route in the dropdown, enter load percentage (if applicable), select continuous routing dropdown. 4. Click Save 5. Click "Apply to all" if you want to apply same routing option to all telephone numbers on this order. </p>
                    <p>Select "Next" when each telephone number has had a Routing Option and Trunk value assigned to it.</p>
                </div>
            </div>
        </div>}
        
        <div className="common-white-shadow-background setting-section tableinput-section">
            <div className="row">
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {formData.methodType !== 2 && <th>Lata</th>}
                            {formData.methodType !== 2 && <th>Rate Center</th>}
                            {formData.methodType !== 2 && <th>State</th>}
                            <th>Tel Number</th>
                            {!trunkIdCount && routeSwitch == 1 && <th>Trunks</th>}
                            {!trunkIdCount && routeSwitch == 0 && <th style={{ paddingTop: "50px" }}> Select Number <input type="checkbox" className="newrow checkboxAll"  onClick={handleSwitchSelectAll} checked={selectAll} /></th>}
                            {!trunkIdCount &&  routeSwitch == 0 && <th width="20%">Routing Option
                               
                                <select name="" id="" value={selectedRoutingOption} 
                                 disabled={!routeOptionDisabled}
                                    onChange={(e) => {setSelectedRoutingOption(e.target.value);
                                        setSelectedRoutingOptionName(e.target.options[e.target.selectedIndex].getAttribute("data-item-name"));
                                        setSelectedRoutingOptionId(e.target.options[e.target.selectedIndex].getAttribute("data-item-value"));
                                    }}>
                                    <option value="">Select</option>
                                {routingList.map((item, index) => (                                                                    
                                <option key={index} value={item.value} data-item-value={item.id} data-item-name={item.name}>
                                    {item.value}
                                </option>  
                                
                            ))}
                                    
                                </select>
                                <a className="search" onClick={handleAssignRoutingOption} href="#">Assign Routing Option</a>
                            </th>}
                           
                           
                            {!trunkIdCount && routeSwitch == 0 && <th> 
                                
                                Remove Number
                               
                            </th>}
                            {/* <th> 
                                
                            </th> */}
                             {!trunkIdCount && routeSwitch == 1 &&<th>Route Option</th>}
                             {!trunkIdCount && routeSwitch == 1 &&<th>Remove Number</th>}
                            {trunkIdCount &&<th>Route Option</th>}
                            {trunkIdCount && <th>Remove Number</th>}
                           
                        </tr>
                    </thead>
                    <tbody>
                    {formData.reserveNumberList.map((item, index) => 
                      
                         <tr key={index} >
                         {formData.methodType !== 2 && <td>{item.lata}</td>}
                         {formData.methodType !== 2 && <td>{item.rateCenterName}</td>}
                         {formData.methodType !== 2 && <td>{item.province}</td>}
                         <td>{item.telephoneNumber}</td>
                         
                         {!trunkIdCount && routeSwitch == 1 && <td >{item.validTrunk ? (<img src={`${process.env.PUBLIC_URL}/images/valid-phone.png`}  onClick={(e) => openModal(item)} />) : (<img src={`${process.env.PUBLIC_URL}/images/trunk-phone.png`}  onClick={(e) => openModal(item)} />)} </td>}
                         {!trunkIdCount && routeSwitch == 0 && <td><input type="checkbox" name="" checked={selectedRows.includes(item.telephoneNumber)}
                  onChange={() => handleToggleSelect(item.telephoneNumber)} /></td>}
                            <td>
                            {routeSwitch == 1 && trunkRouteName.length > 1 ? <p className='green-text'>{routeResults[index]}</p> : <p className="green-text">{formData.tnNumberRouteList && formData.tnNumberRouteList[index]
                                ?  (formData.tnNumberRouteList.find(items => items.telephoneNumber === item.telephoneNumber) || {}).routeOption
                                :  ''}</p>} 
                          
                            </td> 
                            
                           
                         <td><img src="images/delete.png" onClick={event => handleRemoveSelected(event, item)} alt="" /></td>
                     </tr>)}
                        
                    </tbody>
                </table>
                <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel={"Example Modal"}
        // Add your modal styles and content here
      >
       <div className='row'>
        <div className='col-sm-3 cancel-btn'><button onClick={closeModal}>Cancel</button></div>
        <div className='col-sm-9 apply-all'>
           {orderNumberType != 2 && formData.routePath && formData.reserveNumberList && formData.routePath.length != formData.reserveNumberList.length && <button className="apply-btn" onClick={handleUnassignedSet}>Apply to Unassigned</button> }
            {orderNumberType != 2 && <button className="apply-btn" onClick={handleDefaultSet}>Apply to all</button>} 
            <button className="save-btn" onClick={handleSave}>Apply to Number</button></div>
        {/* <div className='col-sm-1 save-btn'></div> */}
       </div>
        {/* <div className='defualt-set'><button onClick={handleDefaultSet}>Set Default</button></div> */}
        <div className="policy">
            <label>Policy</label>
            <div className="switchbtn">
                <select value={selectedPolicy} onChange={handlePolicyChange} >
                    <option value="">Select Policy</option>
                    <option value="1">top_down</option>
                    <option value="2">percentage</option>
                    <option value="3">round_robin</option>
                </select>
            </div>
        </div>
        

      {validationErrors.map((value, index) => (
        <p style={{"color":"red"}} key={index}>{value}</p>
      ))}
    
          
        {/* <div className="row"><label>Policy</label> </div> */}
        <div className='fullwidth-table track-table-body staff-listing-admin-table-body'>
        
            <table>
                <thead>
                    <tr>
                    <th>Route</th>
                    <th>Load Percentage</th>
                    <th>Continuous routing</th>
                    </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <select  value={row.selectedOption}
            onChange={(e) => handleRowChange(e, index, 'selectedOption')}>
                              <option value="">Select Route</option>
                              {trunkRouteName.map((item, index) => (
                                <option key={index} value={item.value} >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                        </td>
                        <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                type="number"
                                value={row.inputText}
                                onChange={(e) => handleRowChange(e, index, 'inputText')}
                              />
                              <span style={{ marginLeft: '5px', width: '50px', height: '25px' }}>
                                %
                              </span>
                            </div>
                        </td>
                        <td>
                            <select value={row.yesNo}
            onChange={(e) => handleRowChange(e, index, 'yesNo')}>
                                {/* <option value="">Select</option> */}
                                <option value="1">Yes</option>
                              <option value="0">No</option>
                              
                            </select>
                        </td>
           </tr> 
          ))}
          
                    

                </tbody>
            </table>
            
            <div className="col-md-6">
                
            </div>
        </div>
        {/* Add your modal content here */}
        {/* <button onClick={closeModal}>Close</button> */}
      </Modal>
            </div>
        </div>
        <div className="row">
            <div className="required-txt"><span className="redtxt">*</span>Required</div>
        </div>
        
        
      {/* <button onClick={onBack}>Back</button>
      <button onClick={onNext}>Next</button> */}
    </>
  )
};

export default Step2;