/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
// import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';

 
const ImportInvenotry = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [importType, setImportType] = useState(0);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        file: false,
        cname: false,
      });
    const [file, setFile] = useState('');

    useEffect(() => {
       if(token){
        getCustomerNameList();
       } 
    }, [token]);

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);;
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            // console.log(allArray);
            //     setLoading(false); 
            // if(allArray.statusCode == 200){
            //     console.log(allArray.data);
            //     setRoutingList(allArray.data)
            // }
           
            
            
          
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

    const handleNextClick = async(e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
            cname: selectedValue === '',
            

          };
         
          setValidationErrors(newValidationErrors);
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
     
        if(!hasErrors){
           
            const newData = new FormData();
                newData.append("file", file);               
                newData.append('company_id', selectedValue);
                setPopupOpen(true);
                let response;
                if(importType === 1){
                    response =  axios.post(`${baseUrl}/import-others-inventory`, newData,{
                        headers: {
                             Authorization: `Bearer ${token}`,
                             "Content-Type": "multipart/form-data",
                        }
                    });
                }else{
                    response =  axios.post(`${baseUrl}/import-did-inventory`, newData,{
                        headers: {
                             Authorization: `Bearer ${token}`,
                             "Content-Type": "multipart/form-data",
                        }
                });
                }
               
                // const allArray = response.data;
                // console.log(allArray); 
                navigate("/order-new-number-success", { state: {
                    importInventory : true
                } }); 
        }
       
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
    };
    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(role > 2 || !hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleRadioChange = (e) => {
        setImportType(Number(e.target.value));
    };

 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    {/* {renderStep()} */}
                    <>
            <div className="row">
                <div className="col-8">
                    <div className="left-title">
                        <h1>Import DID Inventory</h1>
                    </div>
                </div>


                <div className="col-4 top-right-buttons">   
                    <div className="export-btn addnew-button"><Link to="/import-inventory-history">History</Link></div>
                    <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
                    </div>  
                
            </div>
            {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            {validationErrors.cname && (
        <p className="error">Please select a value from the dropdown.</p>
      )}
        </div>}
            
        <div className="common-white-shadow-background setting-section">
                <div className="row">
                
                    <h3>Import Type</h3>
                    <div className="settingbox radio-standard-box">
                        <label for="pastNumber"><input type="radio" id="pastNumber" name="method_type" value={0} checked={importType === 0} onChange={handleRadioChange} />
                            BBD Numbers</label>          
                        <label for="fileUpload"><input type="radio" id="fileUpload" name="method_type" value={1} checked={importType === 1} onChange={handleRadioChange} />
                            Other Numbers</label>
                        
                    </div>
                    
                </div>
            </div>
            
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">
                        {/* {importType === 0 && <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportInvenotry_Template.xlsx`}>Download Template</a>} */}
                        {importType === 0 && <a  className="newrow" target="_blank" href="/files/ImportInvenotry_Template.xlsx">Download Template</a>}
                        {importType === 1 && <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportInventory_Others_Numbers_Template.xlsx`}>Download Template</a>}
                        <p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                    </div>
                    {validationErrors.file && (
        <p className="error">Please choose a file.</p>
      )}
                </div>
            </div>
            
            <div className="row footer-row">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                <a href="#" className="next" onClick={handleNextClick}>Import</a>
            </div>
        </>
                </div>
            </div>
        </div>
    )
}
 
export default ImportInvenotry