import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from '../BaseUrl';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import Loader from '../Loader';
import Pagination from 'react-js-pagination';
import formatDateTimeToPST from '../PSTDateConvert';
import DatePicker from 'react-datepicker';


const NumberCallHistory = () => {
    const location = useLocation();
    const { compid, filter } = location.state || {};
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);

    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(compid);
    const [numberList,setNumberList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [selectedNumber,setSelectedNumber] = useState('');
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [searchDate,setSearchDate] = useState('')
    const [displayDate,setDisplayDate] = useState('')
    const [callHistory,setCallHistory] = useState([])
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
        getCarrierList();
    }, []);

    // useEffect(() => {
    //     if(access.length > 0 && token){
    //         checkAccess(access, token);
    //     }
        
    // }, [access, token]);


    useEffect(()=>{
        if(selectedCompany && token){
            getCustomerCASAccess(selectedCompany);
        }
    },[selectedCompany, token]);

    useEffect(() => {
        if(casAccess && selectedCompany){
            getCiaStatistics(selectedCompany);
        }

    }, [casAccess, selectedCompany])
    
    useEffect(()=>{
        if(selectedCompany){
            getNumberCallHistory(page,selectedCompany,selectedNumber,selectedCarrier,searchDate)
        }
    },[page, casAccess])

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
            seTimeZone(decoded.timezone);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    // const checkAccess = async (access) => {
    //     try {
    //         // setApiCallCount((prevCount) => prevCount + 1);
    //         const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });
    //         console.log(response.data);

    //         if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
    //             navigate('/dashboard');
    //         }

    //         if(role > 2){
    //             setSelectedCompany(cid)
    //             getCustomerCASAccess(cid)
    //         }
            
    //             // if(response.data.cia_enable_switch === 1 && response.data.cia_account_type === 0){
    //             //         if(role > 2) navigate('/dashboard')
    //             // }else{
    //             //     navigate('/dashboard')
    //             // }

    //     } catch (error) {
    //        console.log(error);
    //     }
    // }


    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
        // setCompany(response.data);
    }

    const getCarrierList = async () => {
        const response = await axiosJWT.get(`${baseUrl}/carrier-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        const allArray = response.data.data;
        setCarrierList(allArray);
    }

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCustomerCASAccess = async (cid) => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setCASAccess(true)
                    // getCiaStatistics(cid)
                    // getNumberCallHistory(page,cid,selectedNumber,selectedCarrier,searchDate)
                    setShowNoMessageAccess(false)
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }

        } catch (error) {
           console.log(error);
        }
    };

    const getCiaStatistics = async (cid) => {
        try {
            const response = await axios.get(`${baseUrl}/get-cia-statistics/${cid}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            console.log(response.data)
            setNumberList(response.data?.cia_enabled_numbers_list)
        } catch (error) {
            console.log(error);            
        }
    } 

    const getNumberCallHistory = async (page,cid,number,carrier, date) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-number-call-history?page=${page}&limit=${20}&company=${cid}&number=${number}&carrier=${carrier}&date=${date}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);

        setCallHistory(response.data.data)
        setTotalItem(response.data.totalItem);
    }

    const clearFilter = () => {
        setSelectedNumber("");
        setSelectedCarrier("");
        setSearchDate("");
        setDisplayDate("");
        setPage(1);

        getNumberCallHistory(1,selectedCompany,'','','')
    }

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);  
    };

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const handleSelectedCarrier = (e) => {
        const {name, value} = e.target;
        setSelectedCarrier(value)
        
    }
    const handleSelectedNumber = (data) => {
        setSelectedNumber(data.value)
    }

    const handleSearchSubmit = () => {
        setPage(1);
        getNumberCallHistory(1,selectedCompany,selectedNumber,selectedCarrier,searchDate)
    };
    

    const handleSearchDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchDate(formattedDate);
        setDisplayDate(date);
    };

    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedCompany}}
        navigate("/cas-management", state);
    }

  return (
    <div className="panelbox">
        <Navbar token={token} access={access}/>
        <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Number Call History</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            {/* <Link to="/cas-management"> Back</Link> */}
                        </div>
                    </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedCompany} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                        <div className="row cia-caller-filter">
                            <div className="col-md-8 order-filter">
                                <div className="boxinput">
                                    <Select
                                            classNamePrefix="select"
                                            name="number"
                                            className='search-select'
                                            placeholder="Select Number"
                                            onChange={(selectedOption)=>{
                                                handleSelectedNumber(selectedOption)
                                            }}
                                            options={numberList.map(item => ({
                                                value: item.tn_number,
                                                label: item.tn_number
                                            }))}
                                        />
                                    <select className="form-control" name="selectedCarrier" value={selectedCarrier} onChange={handleSelectedCarrier}>
                                        <option value=''>Carrier</option>
                                        {carrierList?.map((item,index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}         
                                    </select>
                                    <DatePicker
                                    selected={displayDate}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="Date"
                                    className="from-date"
                                />
                                    <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                                    { (selectedNumber || selectedCarrier || searchDate) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}

                                </div>
                            </div>
                        </div>
                        <div className="common-white-shadow-background setting-section">
                            <div className="row">
                                <div className=" setting-section haft-whitebox">
                                    <table className="tablebox search-result-table inventory-table">
                                        <thead>
                                            <tr>
                                                <th>Tel Number</th>
                                                <th>Carrier</th>
                                                <th>Value of release cause</th>
                                                <th>Status</th>
                                                <th>Date-Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {callHistory.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.tn_number}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.value_of_release_cause}</td>
                                                    <td>{item.dialed_status === 1 ? 'Success' : 'Failed'}</td>
                                                    <td>{formatDateTimeToPST(item.call_date_time, timeZone, role)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                </div>
                            </div>
                        </div>
                    </>}
                    
                </div>
        </div>
    </div>
  )
}

export default NumberCallHistory