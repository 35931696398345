/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";


const Analytics = () => {
    const [data, setData] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderPath, setFolderPath] = useState(["uptivity"]);
    const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  
    const handleFolderClick = async(folder) => {
      setCurrentFolder(folder);
      setFolderPath([...folderPath, folder]);
      setPopupOpen(true);
      const response = await axios.get(`${baseUrl}/get-folder-detail?name=${JSON.stringify([...folderPath, folder])}`);
      console.log(response.data.results);
      // setData(response.data.results);
      setFolders(response.data.folders);
      setFiles(response.data.files);
      setPopupOpen(false);
    };
  
    const handleFileDownload = async(fileName) => {
      // Implement file download logic here
      // console.log(`Downloading file: ${fileName}`);
      setPopupOpen(true);
      const path = folderPath.slice(1).map(folder => folder.replace(/\/$/, ''));;
      const fullPath = [...path, fileName].join('/');
      console.log(fullPath, "path")
      const response = await axios.post(`${baseUrl}/download-uptivity-file`, {path : fullPath}, 
      {responseType: 'blob' // Set the response type to 'blob' to handle binary data
    });
    
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the downloaded file name
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up
      link.remove();
      setPopupOpen(false);
    };
  
    const handleBreadcrumbClick = async(index) => {
      if(folderPath.slice(0, index + 1).length == 1){
        fetchData();
      }else{
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/get-folder-detail?name=${JSON.stringify(folderPath.slice(0, index + 1))}`);
        console.log(response.data.results);
        // setData(response.data.results);
        setFolders(response.data.folders);
        setFiles(response.data.files);
        setPopupOpen(false);
      }
     
      setFolderPath(folderPath.slice(0, index + 1));
    };
    

  
    // const getFolderByPath = (path) => {
    //   let folder = data[0];
    //   for (let i = 1; i < path.length; i++) {
    //     folder = folder.subfolder.find((f) => f.folder === path[i]);
    //   }
    //   return folder;
    // };
  
    const renderBreadcrumbs = () => {
      const processedPath = folderPath.map(folder => folder.endsWith('/') ? folder.slice(0, -1) : folder);
      return (
        <div>
          {processedPath.map((folder, index) => (
            <span key={index}>
              {index === 0 ? null : ' / '}
              <a className="breadcrumb-link"  onClick={() => handleBreadcrumbClick(index)}>{folder}</a>
            </span>
          ))}
        </div>
      );
    };
  
  
    // const renderFolder = (folder) => (
    //   <>
    //     {folder.subfolder && folder.subfolder.map((subfolder, index) => (
    //       <tr key={index}>
    //         <td><a className="breadcrumb-link" onClick={() => handleFolderClick(subfolder)}><img src="/images/folder.png" /><span style={{marginLeft: "20px"}}>{subfolder.folder}</span>/</a></td>
    //         <td>
              
    //         </td>
    //       </tr>
    //     ))}
    //     {folder.files.map((file, index) => (
    //       Object.values(file)[0] &&
    //       <tr key={index}>
    //         <td><img src="/images/file.png" /><span style={{marginLeft: "20px"}}>{Object.values(file)[0]}</span></td>
    //         <td>
    //           <a
    //           className="breadcrumb-link"
                
    //             onClick={() => handleFileDownload(Object.values(file)[0])}
    //           >
    //             <img src="/images/download.png" />
    //           </a>
    //         </td>
    //       </tr>
    //     ))}
    //   </>
    // );


  

  const fetchData = async () => {
    try {
      setPopupOpen(true);
      const response = await axios.get(`${baseUrl}/get-uptivity-list`);
      console.log(response.data.results);
      // setData(response.data.results);
      setFolders(response.data.folders);
      setFiles(response.data.files);
      setPopupOpen(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setPopupOpen(false);
    }
  };




  return (
    <div className="panelbox">
    
     <div className="right-panel">
    
     <div className="content-page admin-attendance-page">               
         <div className="row">
             <div className="col-8">
                 <div className="left-title">
                     <h1> Uptivity Recordings</h1>
                 </div>
             </div>
             <div className="col-4 top-right-buttons"> 
                                    
             {/* <div className="export-btn addnew-button"><a onClick={() => handleBack()}><span>&larr;</span> Back</a></div> */}
             </div>
             
         </div>
         {popupOpen && (
                     <Loader /> 
                ) }
         <div className="fullrow">
        
             <div className="row admin-staff-list-toppart">
                 <div className="col-md-8">
                     <div className="boxinput">
                     {/* { breadcrumbs.length > 0 && <tr><td colSpan="2">{renderBreadcrumbs()}</td></tr>}                      */}
                     {renderBreadcrumbs()}
                     </div>
                 </div>
                 
             </div>
             <div className="row">
                 <div className="col-12">
                     <div className="fullwidth-table track-table-body staff-listing-admin-table-body real-time-stats">                                



      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* <tbody>
          {currentFolder ? (
            <>
              {renderFolder(currentFolder)}
            </>
          ) : (
            <>
             {data.map((d, index) => (
                <tr>
                    <td>
                        <a className="breadcrumb-link" onClick={() => handleFolderClick(d)}><img src="/images/folder.png" /><span style={{marginLeft: "20px"}}>{d.folder}</span>/</a>
                    </td>
                    <td>
                
                    </td>
                </tr>
             ))}
            
            </>
          )}
        </tbody> */}
        <tbody>
        {folders.map((folder, index) => (
          <tr key={index}>
          <td><a className="breadcrumb-link" onClick={() => handleFolderClick(folder)}><img src="/images/folder.png" /><span style={{marginLeft: "20px"}}>{folder}</span></a></td>
          <td>
            
          </td>
        </tr>
        ))}
        {files.map((file, index) => {
           const filePath = typeof file === 'object' ? Object.values(file)[0] : file;
           return (
          <tr key={index}>
          <td><img src="/images/file.png" /><span style={{marginLeft: "20px"}}>{filePath}</span></td>
          <td>
            <a
            className="breadcrumb-link"
              
              onClick={() => handleFileDownload(filePath)}
            >
              <img src="/images/download.png" />
            </a>
          </td>
        </tr>
)})}
        </tbody>
      </table>
    
                     </div>
                 </div>
             </div>
         </div>
     </div>                
     </div>
 </div>
);
};



 
export default Analytics