/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import formatDateTimeToPST from "../PSTDateConvert";
// require("bootstrap/less/bootstrap.less");
 
const List = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [cid, setCid] = useState("");
    const [expire, setExpire] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [dialedNumberLog, setDialedNumberLog] = useState([]);
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    // const [company, setCompany] = useState([]);
    // const [selectedCompany, setSelectedCompany] = useState(0);
    // const [selectedUserType, setSelectedUserType] = useState(0);
    // const [searchValue, setSearchValue] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);
    
    useEffect(() => {
        if(cid){
            getCustomerTNSAccess();
        }
    }, [cid]);
    useEffect(() => {
        if(cid){
            getFileUploadLog(page);
        }
        
    }, [page, cid]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    
    const getCustomerTNSAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            // setTNSAccess(response.data.api_enable_switch === 1 ? true : false);
            const checAccess = response.data.api_enable_switch === 1 ? true : false;
            if(!checAccess){
                navigate('/dashboard');
            }
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    // const hasPermission = (permission) => {
    //     return access.some((item) => item.access === permission);
    // };

    // const checkAccess = async (access) => {
    //     if(role > 2 || !tnsAccess){
    //         // console.log(hasPermission('viewuser'));
    //         navigate('/dashboard');
    //     }
    // }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            setTimeZone(decoded.timezone);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getFileUploadLog = async (page) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/dialed-number-file-upload-log?page=${page}&limit=${20}&company=${cid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data);
        setDialedNumberLog(response.data.data);
        setTotalItem(response.data.totalItem);
    }



    const handleFileDownload = async (filePath, type) => {
        try {
          // Make a request to your backend to get the pre-signed URL
          const path = `dialed_number/${type}/${filePath}`;
          const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
          const { presignedUrl } = response.data;
    
          // Create a temporary anchor element and trigger a download
          const link = document.createElement('a');
          link.href = presignedUrl;
          link.download = filePath.split('/').pop(); // Set the filename as the last part of the file path
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        // const newTab = window.open('', '_blank');
        //   newTab.document.write('<html><head><title>JSON Object</title></head><body><pre>' + JSON.stringify(response.data, null, 2) + '</pre></body></html>');
        //   newTab.document.close();
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      };
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>Telephone Number Status</h1>
                        </div>
                    </div>
                    <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            <Link to="/check-dialed-number-status"><span>+</span> Check Number</Link>
                            {role <=2 && <Link to="/review-record-log" className="search"> Review Record</Link>}
                            {role <=2 && <Link to="/tel-number-api-log" className="newrow"> Tel Number Api Log</Link>}
                        </div>
                    </div>
                </div>
                <div className="fullrow">
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                            
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body dialed-number-list">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th style={{ width: '25%' }}>Created By</th>
                                        <th style={{ width: '25%' }}>Input File</th>
                                        <th style={{ width: '25%' }}>Result File</th>
                                        <th style={{ width: '25%' }}>Created At</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dialedNumberLog.map((info, index) => (
                                        <tr key={info.id}>
                                            
                                            <td>{info.name}</td>
                                            <td> <a href="#" onClick={() => handleFileDownload(info.input_file_path, "input")}>{info.input_file_path}</a></td>
                                            <td><a href="#" onClick={() => handleFileDownload(info.result_file_path, "result")}>{info.result_file_path}</a></td>
                                            <td>{formatDateTimeToPST(info.created_at, timeZone, role)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>User Lisiting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table>
                    
                </div> */}
            </div>
        </div>
    )
}
 
export default List