/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import formatDateTimeToPST from "../PSTDateConvert";
// require("bootstrap/less/bootstrap.less");
 
const ReviewRecordLog = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [recordList, setRecordList] = useState([]);
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);

    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);
    useEffect(() => {
        getFileUploadLog(page);
    }, [page]);
    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }


    const checkAccess = async (access) => {
        if(role > 2){
            // console.log(hasPermission('viewuser'));
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getFileUploadLog = async (page) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-cdr-record-review-list?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data);
        setRecordList(response.data.data);
        setTotalItem(response.data.totalItem);
    }


    const handleFileDownload = async (filePath) => {
        try {
          // Make a request to your backend to get the pre-signed URL
          console.log(filePath);
          //   // Make a request to your backend to get the pre-signed URL
            const path = `cdr/recordsForReview/${filePath}`;
          const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
          const { presignedUrl } = response.data;
    
          // Create a temporary anchor element and trigger a download
          const link = document.createElement('a');
          link.href = presignedUrl;
          link.download = filePath.split('/').pop(); // Set the filename as the last part of the file path
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        // const newTab = window.open('', '_blank');
        //   newTab.document.write('<html><head><title>JSON Object</title></head><body><pre>' + JSON.stringify(response.data, null, 2) + '</pre></body></html>');
        //   newTab.document.close();
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      };
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>Review CDR Record</h1>
                        </div>
                    </div>
                    <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            <Link to="/dialed-number/list"> Back</Link>
                        </div>
                    </div>
                </div>
                <div className="fullrow">
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                           
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body real-time-stats">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        
                                    <th>Name</th>
                                    <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {recordList.map((info, index) => (
                                        <tr key={info.id}>
                                            
                                            <td><img src="/images/file.png" /><span style={{marginLeft: "20px"}}>{info.review_path}</span></td>
                                            <td><a className="breadcrumb-link" onClick={() => handleFileDownload(info.review_path)}><img src="/images/download.png" /></a></td>
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </div>
    )
}
 
export default ReviewRecordLog