import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
// import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
import formatDateTimeToPST from '../PSTDateConvert';
import Pagination from 'react-js-pagination';
import { saveAs } from 'file-saver';



const ImportInventoryHistory = () => {
    const [name, setName] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [importHistory, setImportHistory] = useState([])
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);


    const navigate = useNavigate();

    useEffect(() => {
        getImportInventoryHistory();
    }, [page]);

    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if (access.length > 0 && token) {
            checkAccess(access, token);
        }
    }, [access, token]);

    const handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getImportInventoryHistory = async () => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/import-did-inventory-history?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setPopupOpen(false);
        setImportHistory(response.data.data)
        setTotalItem(response.data.totalItem)
    }

    const exportErrors = async (abx) => {
        const keysToInclude = Object.keys(abx).filter(key =>{return key !== 'importError' && key !== 'Success' && key !== 'telephoneNumberOrder'});
        
        const telephoneNumberOrder = abx['telephoneNumberOrder']

        const header = 'Tel Number,Status';
        // const rows = keysToInclude.map(key => `${key},${abx[key]}`);
        const rows = telephoneNumberOrder.map(phoneNumber => {
            const errorMessage = abx[phoneNumber.toString()];
            return `${phoneNumber},${errorMessage}`;
          });
        const csv = `${header}\n${rows.join('\n')}`;;

        // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'ExportError.csv');
    }

    const getExportResult = async (id)=>{
        setPopupOpen(true)
        const response = await axiosJWT.get(`${baseUrl}/import-did-inventory-export-result/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.data)
        const exportResult = response.data.data
        
        await exportErrors(exportResult)

        setPopupOpen(false)
    }

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);

            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setTimeZone(decoded.timezone)

        } catch (error) {

            if (error.response) {
                // setError("Unauthorized");
                navigate("/");
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);

        } catch (error) {
            console.log(error);

        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if (role > 2 || !hasPermission('viewinventory')) {
            navigate('/dashboard');
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    return (
        <div className="panelbox">
            <Navbar token={token} access={access} />
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader />
                )}
                <div className="content-page">

                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Import DID Inventory History</h1>
                            </div>
                        </div>

                        <div className="col-4 top-right-buttons">
                            <div className="export-btn addnew-button"><Link to="/import-inventory"><span>&larr;</span> Back</Link></div>
                        </div>

                    </div>


                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <div className=" setting-section haft-whitebox">
                                <div className="search-result-section">
                                    <h3></h3>
                                    {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                                </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Date ({timeZone})</th>
                                            {/*<th>Title</th>*/}
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {importHistory.map((importItem, index) => (
                                            <tr key={index}>
                                                <td>{formatDateTimeToPST(importItem.created_at, timeZone, role)}</td>
                                                {/* <td>{importItem.title}</td> */}
                                                <td>
                                                    <button onClick={() => { getExportResult(importItem.title)}}>  
                                                        <img src={`${process.env.PUBLIC_URL}/images/download.png`} alt="" />

                                                    </button>
                                                </td>
                                            </tr>))}
                                    </tbody>
                                </table>

                                {totalItem > 20 && (<Pagination
                                    activePage={page}
                                    itemsCountPerPage={20}
                                    totalItemsCount={totalItem}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange.bind(this)}
                                />)}

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ImportInventoryHistory