import React, { useState } from 'react';

const CreateOutboundInputForm = ({ addToList, trunkDisabled }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = inputValue.split(',').map((value) => value.trim());
    const resultArray = [];
    values.forEach((value) => {
      if (value) {
        resultArray.push(value);
      }
    });
    addToList(resultArray);
    
    setInputValue('');
  };

  return (
    <>
    <div className='col-sm-6'>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter TID"
      />
     
    </div>
    <div className='col-sm-6'>
         <input type="submit" className={trunkDisabled ? 'yellow-button disabled-link' : 'yellow-button'}  value="Add" onClick={handleSubmit} />
    </div>
    </>
  );
};

export default CreateOutboundInputForm;
