/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';

 
const TelNumberMonthsDetailLog = () => {
    // const limit = 50;
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [cid, setCid] = useState("");
    const [usageList, setUsageList] = useState([]);
    const [role, setRole] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const month = atob(queryParams.get('month'));
    const year = atob(queryParams.get('year'));
    const company_id = atob(queryParams.get('cid'));

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);
    useEffect(() => {
        if(role){
            checkAccess(role);
        }
    }, [role]);

     useEffect(() => {
        if(company_id && month && year){
            getNumberStatusLog();
        }
        
    }, [company_id, month, year]);

    
        const checkAccess = async (role) => {
            if(role > 2 ){
                navigate('/dashboard');
            }
        }
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
            if(decoded.role >2){
                setSelectedValue(decoded.cid);
            }
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }


    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        }
    }

 
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
            if(decoded.role >2){
                setSelectedValue(decoded.cid);
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
   
    const getNumberStatusLog = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-tns-number-status-log?company=${company_id}&month=${month}&year=${year}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);

        setUsageList(response.data.data);
    }

    const convertToDate = (item) => {
        const day = item.day < 10 ? `0${item.day}`: item.day;
        const month = item.month < 10 ? `0${item.month}`: item.month;
        const year = item.year;

        return `${month}/${day}/${year}`;
    }
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>Number Status Usage Count</h1>
                        </div>
                    </div>
                    <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            <Link to="/tel-number-api-log"> Back</Link>
                        </div>
                    </div>
                </div>
               
                <div className="common-white-shadow-background setting-section">
                    <div className="row">
                        <h3 className='comapny-label'>Daily Usage Count</h3>
                        {usageList.length > 0 && <div className="fullwidth-table track-table-body staff-listing-admin-table-body real-time-stats">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Usage Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usageList.length > 0 && usageList.map((item ,index) => (
                                    <tr key={index}>
                                        <td>{convertToDate(item)}</td>
                                        <td>{item.count}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        {usageList.length === 0 && 
                            <div className="row">
                                No Record Founds.
                            </div>
                        }
                    </div>
                </div>
                
            </div>
                
            </div>
        </div>
    )
}
 
export default TelNumberMonthsDetailLog