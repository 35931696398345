
import React, { useEffect, useState } from 'react'
import Loader from "../../Loader";
import Select from 'react-select';
 
const OrderDetailFilter = ({detail, onFilter}) => {

    const [tnNumberList, setTnNumberList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);  
    const [selectedNumber, setSelectedNumber] = useState('');  
    const [numberStatus, setNumberStatus] = useState('');
    const [sansayStatus, setSansayStatus] = useState('');
    const [romeStatus, setRomeStatus] = useState('');
    const [cnameStatus, setCnameStatus] = useState('');
    const [e911Status, setE911Status] = useState('');
    const [featureDisplay, setFeatureDisplay] = useState(false);
    const [numberDisplay, setNumberDisplay] = useState(false);
    const [sansayDisplay, setSansayDisplay] = useState(false);
    const [romeDisplay, setRomeDisplay] = useState(false);
    const [e911Display, setE911Display] = useState(false);
   
    useEffect(() => {
        if(detail?.tn_details?.length > 0){
            setTnNumberList(detail?.tn_details.map((item) => item.tn_number));
            if((detail.order_type == 0 && detail?.tn_details.filter((item) => item.cname_flag == 1).length > 0))
            {
                setNumberDisplay(true);
                setFeatureDisplay(true);
                setSansayDisplay(true);
                setRomeDisplay(true);

            }
            if(detail.order_type == 3){
                setFeatureDisplay(true);
                setSansayDisplay(true);
                setRomeDisplay(true);
            }
            if(detail.order_type == 0 || detail.order_type == 2){
               
                setNumberDisplay(true);
                setSansayDisplay(true);
                setRomeDisplay(true);
            }
            if(detail.order_type == 4 || detail.order_type == 5){
               
                setSansayDisplay(true);
            }
            
        }
        
    }, [detail]);
    
    

    const handleSearchSubmit = () => {
        let obj = {};
        if(selectedNumber){
            obj.selectedNumber = selectedNumber;
        }
        if(numberStatus){
            obj.numberStatus = numberStatus;
        }
        if(sansayStatus){
            obj.sansayStatus = sansayStatus;
        }
        if(romeStatus){
            obj.romeStatus = romeStatus;
        }
        if(cnameStatus){
            obj.cnameStatus = cnameStatus;
        }
        if(e911Status){
            obj.e911Status = e911Status;
        }
        onFilter(obj);
    };

    const handleClear = () => {
        setNumberStatus('');
        setSansayStatus('');
        setRomeStatus('');
        setCnameStatus('');
        setE911Status('');
        onFilter({});
    }

    
    return (
        <>
        <div className="col-md-12 order-filter transection-filter">
            <div className="boxinput">
                {popupOpen && (<Loader /> )}
                {/* <Select
                    classNamePrefix="select"
                    name="number"
                    placeholder="Select Number"
                    onChange={(selectedOption)=>{
                        handleSelectedNumber(selectedOption)
                    }}
                    options={tnNumberList?.map(item => ({
                        value: item,
                        label: item
                    }))}
                /> */}
                {numberDisplay && <select className="company-name form-control orderStatus" name="number_status" value={numberStatus} onChange={(e) => setNumberStatus(e.target.value)}>
                    <option value="">Number Status</option>
                    <option value="1">Complete</option>
                    <option value="2">Failed</option>
                    <option value="0">In-Progress</option>
                </select>}
                {sansayDisplay && <select className="company-name form-control orderStatus" name="sansay_status" value={sansayStatus} onChange={(e) => setSansayStatus(e.target.value)}>
                    <option value="">Sansay Status</option>
                    <option value="1">Complete</option>
                    <option value="2">Failed</option>
                    <option value="0">In-Progress</option>
                </select>}
                {romeDisplay &&<select className="company-name form-control orderStatus" name="rome_status" value={romeStatus} onChange={(e) => setRomeStatus(e.target.value)}>
                    <option value="">Rome Status</option>
                    <option value="1">Complete</option>
                    <option value="2">Failed</option>
                    <option value="0">In-Progress</option>
                </select>}
                {featureDisplay && <select className="company-name form-control orderStatus" name="rome_status" value={cnameStatus} onChange={(e) => setCnameStatus(e.target.value)}>
                    <option value="">Cnam Status</option>
                    <option value="1">Complete</option>
                    <option value="2">Failed</option>
                    <option value="0">In-Progress</option>
                </select>}
                {e911Display && <select className="company-name form-control orderStatus" name="e911_status" value={e911Status} onChange={(e) => setE911Status(e.target.value)}>
                    <option value="">E911 Status</option>
                    <option value="1">Complete</option>
                    <option value="2">Failed</option>
                    <option value="0">In-Progress</option>
                </select>}
               <button  className="search-btn" onClick={handleSearchSubmit} >Search</button>
               { (numberStatus ||  sansayStatus || romeStatus || cnameStatus || e911Status) && (<a className='search-btn clear-btn'  onClick={handleClear}>Clear</a>)}
            </div>
        </div>
        </>
    )
}
 
export default OrderDetailFilter
