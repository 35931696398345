import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'; // Assuming you're using React Router
import axios from 'axios';
import baseUrl from "./BaseUrl";
import jwt_decode from "jwt-decode";

function SetPassword() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [userId, setUserId] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isMatching, setIsMatching] = useState(true);
    const [validations, setValidations] = useState({
        minLength: false,
        hasLetter: false,
        hasSpecialChar: false,
        hasNumber: false
    });
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({
        password: false,
        confirmPassword: false,
        passwordMatch: false

    });
      
      const formRef = useRef(null);
      useEffect(() => {
        refreshToken();
        // getUserAccess();
    }, [token, navigate]);
    const  refreshToken = async () => {
        try {
            // Verify the JWT token and extract user ID
          //   await axios.get(`${baseUrl}/token`);
            const response = await axios.get(`${baseUrl}/password-token?token=${token}`);
              
            //   setToken(response.data.accessToken);
            // console.log(response.data.token);
              const decoded = jwt_decode(response.data.token);
            //   console.log(decoded);
            const userId = decoded.user_id;
            console.log(decoded);
            setUserId(userId);
            
            
          } catch (error) {
            console.log(error);
           
          }
    }


   

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    
        // Update validations based on the password
        setValidations({
          minLength: newPassword.length >= 8,
          hasLetter: /[a-zA-Z]/.test(newPassword),
          hasSpecialChar: /[!@#$%^&+=]/.test(newPassword),
          hasNumber: /[0-9]/.test(newPassword),
          
        });

        if (confirmPassword !== newPassword) {
            setIsMatching(false);
          } else {
            setIsMatching(true);
          }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);

        
    
        // Check if the confirmation password matches the password
        if (newConfirmPassword !== password) {
          setIsMatching(false);
        } else {
          setIsMatching(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        
        const newValidationErrors = {            
            password: password === '',
            confirmPassword: confirmPassword === '',    
        };
        setValidationErrors(newValidationErrors);

        const checkFiled = Object.values(newValidationErrors).some((error) => error);
        const hasErrors = Object.values(validations).filter((value) => value === true).length;
        
        
        if(!checkFiled && hasErrors == 4 && isMatching){
            try {
                await axios.post(`${baseUrl}/set-password`,{
                    user_id: userId,
                    password: password
                });
                setIsSuccess(true);
                setMsg("Password set successfully. please login here to access your account.");
                // navigate("/");
            } catch (error) {
                // console.log();
                setIsSuccess(false);
                setMsg(error.response.data.error);
                // if (error.response) {
                //     setMsg(error.response.data.msg);
                // }
            }
        }else{
            formRef.current.querySelector('.invalid');
        }
       

    };

    

    return (
        // <div>
        //     <h2>Set Your Password</h2>
        //     <form onSubmit={handleSubmit}>
        //         <div>
        //             <label>New Password:</label>
        //             <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        //         </div>
        //         <div>
        //             <label>Confirm Password:</label>
        //             <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        //         </div>
        //         <button type="submit">Set Password</button>
        //     </form>
        // </div>
        <section className="ragister-login loginpage">
        <div className="row d-flex align-items-center">
            <div className="col-md-5 left-box">
                <div className="inner-slider">

                </div>
            </div>
                <div className="col-md-7 right-box">
                    <div className="form-body">
                        <div className="left-logo">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                        </div>
                        {/* <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p> */}
                        
                        {isSuccess  == false && <form onSubmit={handleSubmit} ref={formRef} className="form-box">
                            
                            <div className="field mt-5">
                                <label className="label">New Password</label>
                                <div className="controls">
                                    <input type="password" className="input" value={password}   onChange={handlePasswordChange} />
                                    {validationErrors.password && <div className="error error-message">Please enter password.</div>}
                                    {validationErrors.passwordPolicy && <div className="error error-message">Password must contain at minimum 8 characters, one special character, one alphabet, and one numeric character.</div>}
                                </div>
                            </div>
                            <div className="field mt-5">
                                <label className="label">Confrim Password</label>
                                <div className="controls">
                                    <input type="password" className="input" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                    {validationErrors.confirmPassword && <div className="error error-message">Please enter confrim password.</div>}
                                    {validationErrors.passwordMatch && <div className="error error-message">Password and confrim password not match.</div>}
                                </div>
                            </div>
                            <div>
        {/* <h3>Password Requirements:</h3> */}
        <ul>
        
          <li className={validations.minLength ? 'valid' : 'invalid'}>
            Minimum 8 characters
          </li>
          <li className={validations.hasLetter ? 'valid' : 'invalid'}>
            At least one letter
          </li>
          <li className={validations.hasSpecialChar ? 'valid' : 'invalid'}>
            At least one special character (e.g., @, #, $)
          </li>
          <li className={validations.hasNumber ? 'valid' : 'invalid'}>
            At least one number
          </li>
          {!isMatching && (
        <li className="error-message">Passwords do not match.</li>
      )}
        </ul>
      </div>
                            <div className="field mt-5 logindiv">
                                {/* <button className="button is-success is-fullwidth">Login</button> */}
                                <input type="submit" value="Set Password"></input>
                            </div>
                        </form>}
                        {isSuccess === true && 
                        <div className="form-box">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>Password set successfully. please <Link to='/'>login here</Link> to access your account.</p>
                        </div>
                        }
                    </div>
                </div>
            
        </div>
    </section>
    );
}

export default SetPassword;
