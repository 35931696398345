
export default function formatDateTimeToPST (dateTimeString,timezone, role) {
    let options;
    if(role <= 2){
         options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,            
        }; 
        if(dateTimeString){
            const year = dateTimeString.slice(0, 4);
            const month = dateTimeString.slice(5, 7);
            const day = dateTimeString.slice(8, 10);
            const time = dateTimeString.slice(11, 19);
            const formattedDateTime = `${month}/${day}/${year} ${time}`;        
           
          
            return formattedDateTime;
        }
        
    }else{
         options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Use 24-hour time format
            timeZone: timezone, // PST timezone
        };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateTimeString)).replace(',', '');
    }
    
      
    
}