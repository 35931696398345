
import Router from './Route';

function App() {
  return (
    <Router />
  );
}

 
export default App;