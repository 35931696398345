/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";

const List = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState('');
    const [error, setError] = useState(null);
    const [access, setAccess] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false); 
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();        
        getUserAccess();
    }, []);
    useEffect(() => {
        getUserCompany(page);
    }, [page]);
    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        console.log(config);
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

   
   
 
    const getUserCompany = async (page) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/user-reseller?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data);
        setUsers(response.data.data);
        setTotalItem(response.data.totalItem);
        
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            // console.log(response.data.results);
            setAccess(response.data.results);
            setIsLoading(false);
            
        } catch (error) {
           console.log(error);
           setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
      };

      const deleteUser = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to disable this user?");
        if (shouldDelete) {
            try {

                await axios.delete(`${baseUrl}/user-company/${id}`,{headers: {
                                Authorization: `Bearer ${token}`
                            }});
                window.location.reload();
            } catch (error) {
                console.log(error);
                // if (error.response) {
                //     setMsg(error.response.data.msg);
                // }
            }
        }
        
    };
    const activateUser = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to re-activate this user?");
        if (shouldDelete) {    
            try {

                await axios.get(`${baseUrl}/user-company-activate/${id}`,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                window.location.reload();
            } catch (error) {
                console.log(error);
                // if (error.response) {
                //     setMsg(error.response.data.msg);
                // }
            }
        }   
    };
    const checkAccess = async (access) => {
        if((role !== 2 && role !== 1 && role !== 0) || (role === 2 && !hasPermission('viewuser'))){
            navigate('/dashboard');
        }
    }
 
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-6">
                        <div className="left-title">
                            <h1>Reseller List</h1>
                        </div>
                    </div>
                    <div className="col-6">
                        {hasPermission('createuser') && <div className="addnew-button">
                          <Link to="/user-reseller-add"><span>+</span> Add Reseller</Link>
                        </div>
                            }

                    </div>
                </div>
                <div className="fullrow">
                    <div className="row admin-staff-list-toppart">
                        {/* <div className="col-md-7">
                            <div className="boxinput">
                                <input className="company-name form-control" type="text" name="Company" placeholder="Company Name" />
                                <select className="form-control" name="usertype" id="">
                                    <option value="0" >User Type</option>
                                    <option value="1">User Type</option>
                                </select>
                                <a href="#">Clear</a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search" />
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Company</th>
                                        <th>E-mails</th>
                                        {/* <th>User Type</th> */}
                                        {/* <th>Active</th> */}
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user, index) => (
                                        <tr key={user.id}>
                                        <td>{user.fname}</td>
                                        <td>{user.lname}</td>
                                        <td>{user.cname}</td>
                                        <td>{user.email}</td>
                                        {/* <td>{user.role === 1 ? 'Super Admin' : user.role === 2 ? 'Support User' : user.role === 3 ? 'Reseller' : user.role === 4 ? 'Customer admin' : 'Customer user'}</td> */}
                                        {/* <td><a href="#" className="false">False</a></td> */}
                                        {/* <td>{user.is_active === 1 ? <a href="#" className="true">True</a> : <a href="#" className="false">False</a> }</td> */}
                                        <td>
                                            <ul>
                                                {hasPermission('edituser') &&  <li><Link to={`/user-reseller/edit/${user.id}`}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit reseller" /></Link></li>}
                                                {user.status === 1 && hasPermission('deleteuser') && <li><a  onClick={() => deleteUser(user.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Delete"  title="Use this option to disable reseller" /></a></li> }
                                                {user.status === 0 && hasPermission('createuser') && <li><a  onClick={() => activateUser(user.id)}><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable reseller" /></a></li> }                                                      
                                            </ul>
                                        </td>
                                    </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>User Lisiting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table>
                    
                </div> */}
            </div>
        </div>
    )
}
 
export default List