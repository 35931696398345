/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
// import Pagination from "react-js-pagination";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';

const Step1 = ({ onNext, cid, role,token,formData, setFormData }) => {
    const [methodType, setMethodType] = useState(0); // 0 for 'Paste Numbers', 1 for 'File Upload'
    const [action, setAction] = useState(0); // 0 for 'Change Route Option', 1 for 'Disconnect'
    const [routingList, setRoutingList] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [telephoneNumbers, setTelephoneNumbers] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedRoutingOption, setSelectedRoutingOption] = useState('');
    const [selectedRoutingOptionName, setSelectedRoutingOptionName] = useState('');
    const [selectedRoutingOptionId, setSelectedRoutingOptionId] = useState('');
    const [showCustomerValidationError, setShowCustomerValidationError] = useState(false);
    const [file, setFile] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const errorListRef = useRef(null);
    useEffect(() => {
       if(token){
        getCustomerNameList();
       }
        
    }, [token]);

    useEffect(() => {
        // if(selectedValue){
            getRoutingOption(selectedValue);
        // }
        
        
    }, [selectedValue, cid]);

    useEffect(() => {
        // Scroll to the errorListRef if Object.entries(errorList).length > 0
        if (Object.entries(errorList).length > 0) {
            console.log("scroll");
          errorListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [errorList]);

    const getRoutingOption = async () => {
        try {
            // const response = await axios.post(`${baseUrl}/routing-option-list`);
            // const allArray = response.data;
            let compId;
            if(role <= 2){
               
                compId = selectedValue;
                // console.log(formData.selectedCompany);
            //     const response = await axios.post(`${baseUrl}/routing-option-list`);
            //     const allArray = response.data;
            
            // setLoading(false); 
            // if(allArray.statusCode == 200){
            //     setRoutingList(allArray.routingOptionList)
            // }
            }
            else{
                compId = cid;
            }
            setFormData({ ...formData, selectedCompany: compId });
            // console.log(compId);
            const response = await axios.get(`${baseUrl}/route-list/${compId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data;
            setLoading(false); 
            if(allArray.statusCode == 200){
                setRoutingList(allArray.data)
            }
          
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);;
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            // console.log(allArray);
            //     setLoading(false); 
            // if(allArray.statusCode == 200){
            //     console.log(allArray.data);
            //     setRoutingList(allArray.data)
            // }
           
            
            
          
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };

    const handleRadioChange = (e) => {
        setMethodType(Number(e.target.value));
        setErrorList([]);
    };
    const handleAction = (e) => {
        setAction(Number(e.target.value));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

    const handleClearClick = (e) => {
        e.preventDefault();
        setTelephoneNumbers(''); // Clear the textarea value
        setIsValid(true);
        setErrorList([]);
    };

    const handleNextClick = async(e) => {
        e.preventDefault();
        let hasCustomerValidationError = false;
        if( role <= 2 && selectedValue === ''){
            setShowCustomerValidationError(true);
            hasCustomerValidationError = true;
        }

        // Check if the input contains only numbers and commas
        const isValidInput = /^[0-9,]+$/.test(telephoneNumbers) &&
      telephoneNumbers.split(',').every(number => number.length === 10);
      if (!selectedRoutingOption && action === 0) {
        alert("Please select routing option")
        // Show a validation error, routing option not selected
        return;
    }
        if (isValidInput && hasCustomerValidationError == false) {
          // Proceed to the next step
          // Add your logic here
          const numbersArray = telephoneNumbers.split(',').map(number => number.trim());
          setFormData({ ...formData, action: action, methodType: methodType, selectedRoutingOptionId: selectedRoutingOptionId,selectedRoutingOption:selectedRoutingOption,selectedRoutingOptionName:selectedRoutingOptionName, disconnectNumberList: numbersArray });
        //   onNext();
         
        //   console.log('Input is valid. Proceeding to the next step.');
        const response = await axios.post(`${baseUrl}/validate-change-route-option-tnnumber`, {tnNumberList:numbersArray,methodType:methodType,company_id:formData.selectedCompany, file:null},{
            headers: {
                 Authorization: `Bearer ${token}`,
                
            }
        });
        const allArray = response.data;
        if(allArray.data){
            setFormData({ ...formData, action: action, methodType: methodType, disconnectNumberList: allArray.data, selectedRoutingOptionId: selectedRoutingOptionId,selectedRoutingOption:selectedRoutingOption,selectedRoutingOptionName:selectedRoutingOptionName });
            onNext();
        }else{
            setErrorList(allArray);
        }
        } 
        if (file && methodType ===  1) {
            console.log(file);
            const data = new FormData();
            data.append("file", file);
            setFormData({ ...formData, action: action, methodType: methodType, selectedRoutingOptionId: selectedRoutingOptionId,selectedRoutingOption:selectedRoutingOption, selectedRoutingOptionName: selectedRoutingOptionName, disconnectNumberList: [], data:data });
        //   onNext();
        const newData = new FormData();
        newData.append("file", file);                
        newData.append('company_id', formData.selectedCompany);
        newData.append('methodType', methodType);
            const response = await axios.post(`${baseUrl}/validate-change-route-option-tnnumber`, newData,{
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                }
            });
        
            const allArray = response.data;
            if(allArray.data){
                setFormData({ ...formData, action: action, methodType: methodType,selectedRoutingOptionId: selectedRoutingOptionId,selectedRoutingOption:selectedRoutingOption, selectedRoutingOptionName: selectedRoutingOptionName, disconnectNumberList: allArray.data,  data:data });
                onNext();
            }else{
                setErrorList(allArray);
            }
        }
        else {
          // Clear the input and display an error message
          setTelephoneNumbers('');
          setIsValid(false);
          
            // alert('Input is not valid. Please enter numbers separated by commas.');
          
          
          console.log('Input is not valid. Please enter numbers separated by commas.');
        }
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
        console.log(formData);
        // setFormData({ ...formData, selectedCompany: e.target.value });
    };


    return(
        <>
            <div className="row">
                <div className="col-6">
                    <div className="left-title">
                        <h1>Modify Active Numbers</h1>
                    </div>
                </div>
                <div className="col-6">
                </div>
            </div>
            {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            {showCustomerValidationError && (
        <p className="error">Please select a value from the dropdown.</p>
      )}
        </div>}
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    <div className="modification-txt">
                        {/* <p>Use <span className="green-text">Search Assigned Numbers</span> page to download current number information.</p> */}
                    </div>
                    <h3>Modification Entry Method</h3>
                    <div className="settingbox radio-standard-box">
                        <label for="pastNumber"><input type="radio" id="pastNumber" name="method_type" value={0} checked={methodType === 0} onChange={handleRadioChange} />
                            Paste Numbers</label>          
                        <label for="fileUpload"><input type="radio" id="fileUpload" name="method_type" value={1} checked={methodType === 1} onChange={handleRadioChange} />
                            File Upload</label>
                        
                    </div>
                    
                </div>
            </div>
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Modification Type</h3>
                    <div className="settingbox radio-standard-box">
                        <label for="changeRoute"><input type="radio" name="Search" id="changeRoute" value={0} checked={action === 0} onChange={handleAction} />
                           Change Routing Option</label>
                        {/* <label for="disconnect"><input id="disconnect" type="radio" name="Search"  value={1} checked={action === 1} onChange={handleAction} />
                            Disconnect</label> */}
                       
                    </div>
                    {action === 0 && 
                    <div className="row new-route-option"  style={{ marginTop: '30px' }}>
                        <h3>New Routing Option <span className="redtxt">*</span></h3>
                        <div className="modification-txt">
                            {/* <p>Use <span className="green-text">Routing Options</span> page to view routing details.</p> */}
                        </div>
                        <div className="inputbox">
                            <select name="" id="" value={selectedRoutingOption}
                                    onChange={(e) => {setSelectedRoutingOption(e.target.value);
                                        setSelectedRoutingOptionName(e.target.options[e.target.selectedIndex].getAttribute("data-item-name"));
                                        setSelectedRoutingOptionId(e.target.options[e.target.selectedIndex].getAttribute("data-item-value"));}}>
                                <option value="">Select</option>
                                {routingList.map((item, index) => (                                                                    
                                <option key={index} value={item.value} data-item-value={item.id} data-item-name={item.name}>
                                    {item.value}
                                </option>  
                                ))}
                            </select>
                        </div>
                    </div>}
                </div>
            </div>
            {methodType === 0 ? (
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Tel Numbers*</h3>
                    
                   
                    <div className="tel-number-textarea">
                         <p>Paste or manually enter Tel Numbers, separated by characters such as: space, comma, tab, or paragraph return.</p>
                         <input type="textarea" value={telephoneNumbers} onChange={(e) => setTelephoneNumbers(e.target.value)} />
                        <a className="unassigned" href="#" onClick={handleClearClick}>Clear</a>
                    </div>
                </div>
            </div>
             ) : (
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">
                        <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/Change_RoutingOption_Template.xlsx`}>Download Template</a>
                        <p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                    </div>
                    
                </div>
            </div>
             )}
            <div className="row footer-row">
            <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                <a href="#" className="next" onClick={handleNextClick}>Next</a>
            </div>
            
            <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>{ Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}<table  className="tablebox search-result-table order-result-table">
                                        
                                        <tbody>
                                            {Object.entries(errorList).map(([key, value]) => (
                                                 key !== 'statusCode' && key !== 'show' && (
                                              <tr key={key}>
                                                <td><strong>{key}:</strong></td>
                                                <td >{key === 'status' ? (
            value
              .split(',')
              .map((item, index) => (
                <p key={index}>{item.trim()}</p>
              ))
          )  :
            key === 'result' && Array.isArray(value) ? (
              value.map((item, index) => (
                <p key={index}>{item}</p>
              ))
            ) : (
              value
            )}</td>
                                              </tr>
                                            )))}
                                        </tbody>
                                    </table></div>
        </>
    );
}

const Step2 = ({ onBack, onNext, token, formData, setFormData  }) => {

    const [contacts, setContacts] = useState([
        { name: '', phone: '', extension: '', alias: '' }
    ]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (validationErrors.length > 0) {
            const errorMessages = validationErrors.join('\n');
            alert(errorMessages);
        }
    }, [validationErrors]);

    const handleContactChange = (e, index) => {
        const { name, value } = e.target;
        const updatedContacts = [...contacts];
        const fieldName = name.split('-')[0];
        updatedContacts[index][fieldName] = value;
    
        setContacts(updatedContacts);
    };

    const contactInputs = contacts.map((contact, index) => (
        <div className="row order-contect" key={index}>
        <div className="labelwithbox" >
            <label>Name</label>
            <div className="inputbox">
                <input
                    type="text"
                    name={`name-${index}`}
                    value={contact.name}
                    onChange={(e) => handleContactChange(e, index)}
                    required
                />
            </div> </div>
            {/* <div className="labelwithbox">
                    <label>Work Phone</label>
                    <div className="inputbox">
                         <input type="text" name={`phone-${index}`} value={contact.phone}
                    onChange={(e) => handleContactChange(e, index)}
                    required />
                    </div>
                </div>
                <div className="labelwithbox">
                    <label>Extension</label>
                    <div className="inputbox">
                        <input type="text" name={`extension-${index}`} value={contact.extension}
                    onChange={(e) => handleContactChange(e, index)}
                     />
                    </div>
                </div> */}
                 <div className="labelwithbox">
                    <label>Email</label>
                    <div className="inputbox">
                        <input type="email" name={`alias-${index}`} value={contact.alias}
                    onChange={(e) => handleContactChange(e, index)} required />
                    </div>
                </div>
                {/* <div className="labelwithbox">
                    <label></label>
                    <div className="inputbox">
                       
                        {index === 0 ? (
                            <a className="newrow" onClick={handleAddContact} href="#">+ Add Contact</a>
                        ) : (
                            <a className="newrow" onClick={() => handleRemoveContact(index)} href="#">Delete</a>
                        )}
                    </div>
                </div> */}
        </div>
       
    ));

    const handleNext = async(e) => {
        e.preventDefault();
        const errors = contacts.map((contact, index) => {
            const contactErrors = [];

            // if (!contact.name) {
            //     contactErrors.push(`Row ${index + 1}: Name is required`);
            // }
            // if (!/^\d{3}-\d{3}-\d{4}$/.test(contact.phone)) {
            //     contactErrors.push(`Row ${index + 1}: Phone is invalid and format is 111-111-1111`);
            // }
            // if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.alias)) {
            //     contactErrors.push(`Row ${index + 1}: Email is invalid`);
            // }

            return contactErrors;
        });
        setValidationErrors(errors.flat());

        if (errors.some(rowErrors => rowErrors.length > 0)) {
            return;
        }else{
            console.log(formData);
            const  routeOptionName = formData.selectedRoutingOptionName;
            const  routeOptionId = formData.selectedRoutingOptionId;
            const  routeOption = formData.selectedRoutingOption;
            
            if(formData.methodType === 0){
                setButtonDisabled(true);
                setPopupOpen(true);
                const response = await axios.post(`${baseUrl}/change-route-option-tnnumber`, {tnNumberList:formData.disconnectNumberList, company_id: formData.selectedCompany, routeOption:routeOption, routeOptionName:routeOptionName,routeOptionId:routeOptionId, contacts: contacts},{
                    headers: {
                         Authorization: `Bearer ${token}`
                    }
                });
                const allArray = response.data;

                navigate("/order-new-number-success", { state: allArray }); 
            }else{
                setButtonDisabled(true);
                setPopupOpen(true);
                const newData = new FormData();
                newData.append("file", formData.data.get("file"));
                newData.append("contacts", JSON.stringify(contacts));
                newData.append("routeOption", routeOption);
                newData.append("routeOptionName", routeOptionName);
                newData.append("routeOptionId", routeOptionId);
                newData.append('company_id', formData.selectedCompany);

                const response = await axios.post(`${baseUrl}/upload-change-route-option-tnnumber`, newData,{
                        headers: {
                             Authorization: `Bearer ${token}`,
                             "Content-Type": "multipart/form-data",
                        }
                });
                const allArray = response.data;
                console.log(allArray); 
                navigate("/order-new-number-success", { state: allArray }); 
            }
        }
    }

    return(
        <>
        {popupOpen && (
                     <Loader /> 
                ) }
            <div className="row">
                <div className="col-6">
                    <div className="left-title">
                        <h1>Modify Active Numbers</h1>
                    </div>
                </div>
                <div className="col-6">
                </div>
            </div>
            <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Secondary Order Contact</div>
                    
                </div>
                {contactInputs}
                {/* <div className="row">
                    <div className="required-txt"><span className="redtxt">*</span>Required</div>
                </div> */}
                <div className="row footer-row">
                    <div className="footer-row-left">
                        <a href="#" onClick={onBack} className="Back"> <img src="images/back-arrow.png" />Back</a>
                    </div>
                    <div className="footer-row-right">
                    <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                        <a href="#"  onClick={handleNext} className={buttonDisabled ? 'next disabled-link' : 'next'}>Modify</a>
                    </div>
                </div>
            </div>
        </>
    );
}
 
const ModifyNumber = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState([]);
    const [step, setStep] = useState(1);
   
    

    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} cid={cid} role={role} token={token} formData={formData} setFormData={setFormData} />;
          case 2:
            return <Step2 onBack={handleBack} onNext={handleNext} token={token} formData={formData} setFormData={setFormData} />;
        //   case 5:
        //     return <Step5 data={formData} onFinish={handleFinish} />;
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default ModifyNumber