
import React, { useState } from 'react'
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';
 
const Header = (props) => {
    const navigate = useNavigate();
    // const [showProfileLink, setShowProfileLink] = useState(false);
    // const [access, setAccess] = useState([]);

    // const handleInitialsHover = () => {
    //     setShowProfileLink(true);
    // };

    // const handleInitialsLeave = () => {
    //     setShowProfileLink(false);
    // };

    const handleProfileLinkClick = () => {
        // Navigate to the user's profile page
        navigate('/profile'); // Replace '/profile' with the actual URL of the profile page
    };
    // const currentDomain = window.location.hostname;
    // console.log(currentDomain);
    return (
        <div className="header">
            <div className="right-admindetail">
                <p>
                    <img  className="profile" onClick={handleProfileLinkClick} src={`${process.env.PUBLIC_URL}/images/bx-user.png`} alt="" />
                    {/* <img src={`${process.env.PUBLIC_URL}/images/notification.png`} />
                    <span  >{props.name.charAt(0)}</span> */}
                    
                </p>
            </div>
            {/* {showProfileLink && (
                        <div className="profile-link" onClick={handleProfileLinkClick}>
                            Profile
                        </div>
                    )} */}
        </div>
    )
}
 
export default Header