import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import { saveAs } from 'file-saver';
import formatDateTimeToPST from "../PSTDateConvert";
import DatePicker from 'react-datepicker';
import Pagination from "react-js-pagination";


const CiaNumberDetail = () => {
    const location = useLocation();
    const { compid, type, carrier,filter } = location.state || {};
    const [name, setName] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(compid);
    const [filterType, setFilterType] = useState(0);
    const [selectedCarrier,setSelectedCarrier] = useState(carrier ?? "0");
    const [selectedStatus,setSelectedStatus] = useState(type ?? "all");
    const [carrierColumnName, setCarrierColumnName] = useState([]);
    const [dateColumnName, setDateColumnName] = useState([]);
    const [carrierNumberList, setCarrierNumberList] = useState([]);
    const [casAccess, setCASAccess] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false);
    const [filterSearch, setFilterSearch] = useState(false);
    const [tableData, setTableData] = useState([]);
    const today = new Date()
    const sixMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 6));
    const [searchDate, setSearchDate] = useState(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
    const [searchEndDate, setSearchEndDate] = useState();
    const [displayDate, setDisplayDate] = useState(today);
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [carrierList, setCarrierList] = useState([]);

    const [formData,setFormData] = useState({
        selectedCompany: compid
    })
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(()=>{
        if(formData.selectedCompany && token){
            getCustomerCASAccess(formData.selectedCompany)
        }
    },[formData.selectedCompany, access, token])

    // useEffect(() => {
    //     if(access.length > 0 && token){
    //         checkAccess(access, token);
    //     }
        
    // }, [access, token]);

    useEffect(() => {
        if(token && compid && filter){
            setFilterType(1);
            
            getCiaMostRecentDetail(1, 1,compid, carrier);
        }
        
    }, [compid, token, filter]);
   

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
        // setCompany(response.data);
    }

   
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleSearchSubmit = () => {
        setFilterSearch(true);
        setPage(1);
        
        getCiaMostRecentDetail(filterType,1,formData.selectedCompany, selectedCarrier);
    };

    const clearFilter = () => {
        setPage(1);
        setFilterType(0);
        setSelectedCarrier("0");
        setSelectedStatus("all");
        setFilterSearch(false);
        setTableData([]);
        setCarrierColumnName([]);
        setDateColumnName([]);
        setCarrierNumberList([]);
        setSearchDate(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
        setDisplayDate(today);
        setDisplayEndDate("");
        setSearchEndDate("");
        // getCiaMostRecentDetail(0, 1,formData.selectedCompany, "");
    };
    const getCiaMostRecentDetail = async (filterType, page,cid, carrier) => {
        try {
            setPopupOpen(true);
            if(filterType == 1){
                
            
                const response = await axios.get(`${baseUrl}/get-cia-detail-carrier-statistics/${cid}?page=${page}&limit=${20}&carrier=${carrier}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                let columnList = ["Phone Number", "Carrier"];
                let list = [];
                if(response?.data?.cia_number_detail){
                    const dateKeys = Object.keys(response.data.cia_number_detail);
                    columnList = columnList.concat(dateKeys);
                    list = Object.values(response.data.cia_number_detail);
                    const status = selectedStatus == 0 ? "Unverified" : (selectedStatus == 1 ? "Verified" : (selectedStatus == 2 ? "Spam" : (selectedStatus == 3 ? "Unknown" :"")));
                    if (dateKeys.length > 0 && status) {
                       
                        columnList[2] = `${dateKeys[0]} (${status})`;
                    }
                }
                setCarrierColumnName(columnList);
                setCarrierNumberList(list)
                
                
            }

            if(filterType == 2){
                const response = await axios.get(`${baseUrl}/get-cia-detail-date-statistics/${cid}?page=${page}&limit=${20}&from_date=${searchDate}&to_date=${searchEndDate}`,{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const carrier_list = response?.data?.carrier_list ?? [];
                
                let columnList = ["Phone Number", "Date"];
                
                const carrierNames = carrier_list.flatMap(carrier => [carrier.name, 'Caller Id']);
                const updatedColumnList = columnList.concat(carrierNames);
                setDateColumnName(updatedColumnList);
                setCarrierList(carrier_list);
               
                // const tableData = response?.data?.cia_number_detail.map((item) => {
                //     const row = {
                //         tn_number: item.tn_number,
                //         date: item.date.split(' ')[0]
                //     };
                //     carrier_list.forEach(carrier => {
                //         row[carrier.name] = '-';
                //     });
                
                //     if (item.carrier === '-') {
                //         Object.keys(row).forEach(key => {
                //             if (carrier_list.some(c => c.name === key)) {
                //                 row[key] = '-';
                //             }
                //         });
                //     } else {
                //         // Set the specific carrier column value
                //         const carrierName = carrier_list.find(c => c.id.toString() === item.carrier)?.name;
                //         if (carrierName) {
                //             const status = item.status === 0 ? "Unverified" : (item.status === 1 ? "Verified" : (item.state === 2 ? "Spam"  : '-'));
                //             row[carrierName] = status;
                //         }
                //     }
                
                //     return row;
                // });
                setTableData(response?.data?.cia_number_detail);
            }
            setPopupOpen(false);
        } catch (error) {
            console.log(error);  
            setPopupOpen(false);          
        }
    }

    // const checkAccess = async (access) => {
    //     try {
    //         const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }       
    //         });

    //         if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
    //             navigate('/dashboard');
    //         }

    //         if(role > 2){
    //             setFormData(prev => ({...prev, selectedCompany:cid}))
    //             getCustomerCASAccess(cid)
    //         }
            
    //             // if(response.data.cia_enable_switch === 1 && response.data.cia_account_type === 0){
    //             //         if(role > 2) navigate('/dashboard')
    //             // }else{
    //             //     navigate('/dashboard')
    //             // }

    //     } catch (error) {
    //        console.log(error);
    //     }
    // }

    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }

    const getCustomerCASAccess = async (cid) => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
                navigate('/dashboard');
            }
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setShowNoMessageAccess(false);
                    setCASAccess(true)
                    
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);  
        setFormData({ ...formData, selectedCompany: e.target.value });
        clearFilter();

    };

    

    const handleExportCSV = async (e) => {
        e.preventDefault();
        if(filterType == 1){
            const columnList = [];
            if(carrierColumnName[0] || carrierColumnName[1]){
                columnList[0] = "";
                columnList[1] = "";
            }
            if(carrierColumnName[2]){
                columnList[2] = "Status";
                columnList[3] = "Caller Id"; 
            }
            if(carrierColumnName[3]){
                columnList[4] = "Status";
                columnList[5] = "Caller Id";
            }
            if(carrierColumnName[4]){
                columnList[6] = "Status";
                columnList[7] = "Caller Id";
            }
            if(carrierColumnName[5]){
                columnList[8] = "Status";
                columnList[9] = "Caller Id";
            }
            // console.log(tableData[4])
            const csvContent = tableData.map(item => {
                
                return item.map(val => val.toString().replace(/,/g, ' '));
                
            }).join("\n");
            const csv = [carrierColumnName,columnList, csvContent].join('\n');
            const blob = new Blob([csv], { type: 'text/csv' });

            saveAs(blob, 'cia_enable_number.csv');
        }else if(filterType == 2){
            const csv = [dateColumnName].concat(
                tableData.map(item => {
                    return [
                        item.tn_number,
                        item.date,
                        ...carrierList.map((val) => {
                            return `${item[`carrier_${val.id}`]},${item[`caller_${val.id}`].toString().replace(/,/g, ' ')}`;
                          })
                    ].join(',');
                })).join('\n');
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          
            // Trigger the file download using file-saver
            saveAs(blob, 'cia_enable_number.csv');
        }
       
    }

    useEffect(() => {
        setPopupOpen(true);
        const phoneNumbers = new Set();
        const carriers = new Set();
        const dates = Object.keys(carrierNumberList);

        // Collect unique phone numbers and carriers
        for (const date in carrierNumberList) {
            carrierNumberList[date].forEach(item => {
                phoneNumbers.add(item.tn_number);
                carriers.add(item.carrier);
            });
        }

        const phoneNumbersArray = Array.from(phoneNumbers);
        const carriersArray = Array.from(carriers);

        let rows = phoneNumbersArray.flatMap(phoneNumber =>
            carriersArray.map(carrier => {
                const row = [phoneNumber, carrier];
                dates.forEach(date => {
                    const item = carrierNumberList[date].find(i => i.tn_number === phoneNumber && i.carrier === carrier);
                    const status = (item.status === 0 ? "Unverified" : (item.status === 1 ? "Verified" : (item.status === 2 ? "Spam"  : 'Unknown')));
                    row.push(status);
                    row.push(item?.caller_list ?? "-");
                });
                return row;
            })
        );
        const status = selectedStatus == 0 ? "Unverified" : (selectedStatus == 1 ? "Verified" : (selectedStatus == 2 ? "Spam" : (selectedStatus == 3 ? "Unknown" : "")));
        if(status){

            rows = rows.filter(row => row[2] === status);
        }
        setTableData([ ...rows]);
        setPopupOpen(false);
    }, [carrierNumberList]);

    const handleSearchDate = (date, name) => { 
        const formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
            date.getDate().toString().padStart(2, '0');
        if(name == "from_date"){
            setSearchDate(formattedDate);
            setDisplayDate(date);
        }else if(name == "end_date"){
            setSearchEndDate(formattedDate);
            setDisplayEndDate(date);
        }
    };
   
    const handleFilterChange =  (e) => {
        setFilterType(e.target.value);
        setTableData([]);
        setCarrierColumnName([]);
        setDateColumnName([]);
    }

    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedValue}}
        navigate("/cas-management", state);
    }


  return (
    <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>E-NRMS Enabled Number</h1>
                        </div>
                    </div>
                    <div className="col-4 inventory-btn">
                    
                        <div className="addnew-button">
                        <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            {/* <Link to="/cas-management">Back</Link> */}
                        </div>
                    </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                    
                        
                        <div className='common-white-shadow-background setting-section'>
                            
                            <div className='row'>
                                <div className='col-8'>
                                <div className='boxinput'>
                                    <select className="form-control" name='filter_type' value={filterType} onChange={(e)=>{handleFilterChange(e)}}>
                                        <option value='0'>Select a Filter</option>
                                        <option value='1'>By Carrier</option>
                                        <option value='2'>By Date</option>
                                    </select>
                                    {filterType == 1 && <select className="form-control" name='carriertype' value={selectedCarrier} onChange={(e)=>{setSelectedCarrier(e.target.value)}}>
                                        <option value='0'>All</option>
                                        <option value='1'>T-Mobile</option>
                                        <option value='2'>ATT</option>
                                        <option value='3'>Verizon</option>
                                    </select>}
                                    {filterType == 1 && <select className="form-control" name='carrierStatus' value={selectedStatus} onChange={(e)=>{setSelectedStatus(e.target.value)}}>
                                        <option value='all'>All</option>
                                        <option value='0'>Unverified</option>
                                        <option value='1'>Verified</option>
                                        <option value='2'>Spam</option>
                                        <option value='3'>Unknown</option>
                                        
                                    </select>}
                                    {filterType == 2 && 
                                    <>
                                    <DatePicker
                                        selected={displayDate}
                                        onChange={(e) => {handleSearchDate(e, "from_date")}}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="From Date"
                                        className="from-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    />
                                    <DatePicker
                                        selected={displayEndDate}
                                        onChange={(e) => {handleSearchDate(e, "end_date")}}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="To Date"
                                        className="end-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    /> </>}
                                    
                                    
                                    {(filterType == 1 || filterType == 2) && <button className="search-btn" style={{marginLeft:'10px'}} onClick={handleSearchSubmit}>Search</button>}
                                    {(filterType == 1 || filterType == 2) && selectedCarrier && <a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>}
                                </div>
                                </div>
                                <div className="col-4 inventory-btn">
                                    <div className="addnew-button">
                                        <a className="newrow" onClick={handleExportCSV} href="#">Export</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="setting-section haft-whitebox cia-table">
                                    {filterType == 1 && <table className="tablebox search-result-table inventory-table cia-number-table">
                                        {carrierColumnName && carrierColumnName.length > 0 && <thead>

                                            <tr>
                                                
                                                {carrierColumnName[0] && <th>{carrierColumnName[0]}</th>}
                                                {carrierColumnName[1] && <th>{carrierColumnName[1]}</th>}
                                                {carrierColumnName[2] && <th style={{textAlign: "center"}} colSpan={2}>{carrierColumnName[2]}</th>}
                                                {carrierColumnName[3] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[3]}</th>}
                                                {carrierColumnName[4] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[4]}</th>}
                                                {carrierColumnName[5] && <th style={{textAlign: "center"}}  colSpan={2}>{carrierColumnName[5]}</th>}
                                            </tr>
                                            <tr>
                                                
                                                {carrierColumnName[0] && <th></th>}
                                                {carrierColumnName[1] && <th></th>}
                                                {carrierColumnName[2] && <th>Status</th>}
                                                {carrierColumnName[2] && <th>Caller Id</th>}
                                                {carrierColumnName[3] && <th>Status</th>}
                                                {carrierColumnName[3] && <th>Caller Id</th>}
                                                {carrierColumnName[4] && <th>Status</th>}
                                                {carrierColumnName[4] && <th>Caller Id</th>}
                                                {carrierColumnName[5] && <th>Status</th>}
                                                {carrierColumnName[5] && <th>Caller Id</th>}
                                            </tr>
                                        </thead>}
                                        <tbody>
                                        {tableData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <td key={cellIndex}>{cell}</td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}
                                    {filterType == 2 && <table className="tablebox search-result-table inventory-table">
                                        {dateColumnName && dateColumnName.length > 0 && <thead>

                                            <tr>
                                                
                                                {dateColumnName[0] && <th>{dateColumnName[0]}</th>}
                                                {dateColumnName[1] && <th>{dateColumnName[1]}</th>}
                                                {dateColumnName[2] && <th>{dateColumnName[2]}</th>}
                                                {dateColumnName[3] && <th>{dateColumnName[3]}</th>}
                                                {dateColumnName[4] && <th>{dateColumnName[4]}</th>}
                                                {dateColumnName[5] && <th>{dateColumnName[5]}</th>}
                                                {dateColumnName[6] && <th>{dateColumnName[6]}</th>}
                                                {dateColumnName[7] && <th>{dateColumnName[7]}</th>}
                                            </tr>
                                        </thead>}
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                               
                                                    <td>{row.tn_number}</td>
                                                    <td>{formatDateTimeToPST(row.date, 'UTC', role)}</td>
                                                    {carrierList.map((item, i) => (
                                                        <React.Fragment key={`${rowIndex}-${i}`}>
                                                         <td key={`carrier_${i}`}>{row[`carrier_${i + 1}`]}</td>
                                                         <td key={`caller_${i}`}>{row[`caller_${i + 1}`]}</td>
                                                         </React.Fragment>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>}
                                    {totalItem > 20 && (<Pagination
                                      activePage={page}
                                      itemsCountPerPage={20}
                                      totalItemsCount={totalItem}
                                      pageRangeDisplayed={5}
                                      onChange={handlePageChange.bind(this)}
                                    />)}
                                </div>
                            </div>
                        </div>
                        
                    </>}

                </div>
            </div>
    </div>
  )
}

export default CiaNumberDetail