import React, { useEffect, useState } from 'react'
import {useNavigate,useLocation} from 'react-router-dom'
import baseUrl from "./BaseUrl";
import axios from 'axios';



const InactivityTimeout = ({children}) => {
    const [userActive,setUserActive] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();

    const isLoginPage = location.pathname === '/';


    const Logout = async () => {
        try {
            // setPopupOpen(true);
            await axios.delete(`${baseUrl}/logout`);
            navigate("/");
        } catch (error) {
            console.log(error);
            // setPopupOpen(false);
        }
    }

    const handleInactivityTimeout = async () => {
        setUserActive(false);
        await Logout()
        // alert('You have been inactive for 20 seconds. Logging out for security.');
      };

    useEffect(()=>{
        let timeoutId;
        
        const handleActivity = () => {
            clearTimeout(timeoutId); // Clear the timeout whenever activity is detected
            setUserActive(true);
            timeoutId = setTimeout(handleInactivityTimeout, 2*60*60*1000);
        };
                

        if(!isLoginPage){
            timeoutId = setTimeout(handleInactivityTimeout, 2*60*60* 1000)
            // Add event listeners to track user activity
            document.addEventListener('mousemove', handleActivity);
            document.addEventListener('keypress', handleActivity);
        }
       

        return () => {
            // Cleanup event listeners on component unmount
            clearTimeout(timeoutId)
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keypress', handleActivity);
        };    
    },[location.pathname])

  return (
    <>{children}</>
  )
}

export default InactivityTimeout