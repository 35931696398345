/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
 
const ViewTransactions = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [company, setCompany] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState("");
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        // getOrder(1);
        getUserCompany();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    useEffect(() => {
        getOrder(page,selectedCompany, searchValue, status);
    }, [page]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        setCompany(response.data);
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    const getOrder = async (page, cid, search, status) => {
        // console.log(page);
        const response = await axiosJWT.get(`${baseUrl}/orders?page=${page}&limit=${20}&company=${cid}&search=${search}&status=${status}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setOrders(response.data.data);
        setTotalItem(response.data.totalItem);
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const handleComapnyChange = (e) => {
        setSelectedCompany(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,e.target.value, selectedUserType); // Reset page to 1 when filter changes
    };

    const clearFilter = () => {
        setStatus("");
        setSelectedCompany(0);
        setSearchValue('');
        setPage(1);
        // getUsers(page,0, 0, '');
        getOrder(1,0,'', '');
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleUserTypeChange = (e) => {
        setStatus(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getOrder(1,selectedCompany,searchValue, status);
        // getUsers(1, selectedCompany, selectedUserType, searchValue);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>View Transactions</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 order-filter ">
                        <div className="boxinput">
                            
                            <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search" value={searchValue} onChange={handleSearchChange} />
                            <select className="form-control" name="usertype" value={status} onChange={handleUserTypeChange}>
                                 <option value="" >Transaction Status</option>                                    
                                 <option value="0">In Service</option>
                                 <option value="1">Close</option>
                                 <option value="2">Pending</option>
                                
                             </select>
                               {role <=2 && <select className="company-name form-control" name="company" value={selectedCompany} onChange={handleComapnyChange}>
                                    <option value={0} >Company</option>
                                    {company.map((comp, index) => (
                                                              <option key={index} value={comp.id}>
                                                                {comp.cname}
                                                              </option>
                                                            ))}
                                </select>}
                                
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { (selectedCompany ||  status|| searchValue) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        <div className="col-6 top-right-buttons">
                            {/* <Link className="search" to={"/order-new-number"}>Order Number</Link> */}
                            {/* <a className="unassigned" href="#">Request Numbers</a> */}
                        </div>
                    </div>
                   
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Transactions ID</th>
                                            {role <= 2 && <th>Company</th>}
                                            <th>Order Type</th>
                                            {/* <th>Submit Date</th> */}
                                            {/* <th>Order Status</th> */}
                                            <th>Quantity</th>
                                            {/* <th>Confirmed Due Date</th> */}
                                            <th>Created By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                        <td><Link to={{ pathname: `/view-order-detail/${order.unique_id}`, search: `?type=false`}} >{order.unique_id}</Link></td>
                                        {role <= 2 && <td>{order.cname}</td>}
                                        <td>{order.order_type == 0 ? "New" : (order.order_type == 1 ? "Change" : "Disconnect")}</td>
                                        {/* <td></td> */}
                                        {/* <td>{order.order_status == 0 ? "In Service" : (order.order_status == 1 ? "Close" : "Pending")}</td> */}
                                        <td>{order.quantity}</td>
                                        {/* <td></td> */}
                                        <td>{order.fname} {order.lname}</td>
                                        </tr>))}
                                        {/* <tr>
                                            <td>1571470</td>
                                            <td>TestZoliABS_2023_03_17_19_</td>
                                            <td>Disconnect</td>
                                            <td>04/14/2023</td>
                                            <td><span className="redtxt">Canceled</span></td>
                                            <td>00</td>
                                            <td>04/14/2023</td>
                                            <td>api_user</td>
                                        </tr> */}
                                         </tbody>
                                </table>
                                {/* <div className="recored-setup"> */}
                                    {/* <label for="cars">Records Per Page </label>

                                    <select name="cars" id="cars">
                                      <option value="volvo">1</option>
                                      <option value="saab">2</option>
                                      <option value="mercedes">3</option>
                                      <option value="audi">4</option>
                                    </select> */}
                                     {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
 
export default ViewTransactions