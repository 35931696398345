/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import formatDateTimeToPST from "../PSTDateConvert";
import { saveAs } from 'file-saver';
import Loader from "../Loader";
 
const TrunkCronSync = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [trunkLog, setTrunkLog] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
       
        getTrunkLog();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    useEffect(() => {
        getTrunkLog();
    }, [page]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const getTrunkLog = async () => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-trunk-log?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        const logList = response.data;
        logList.data.sort((a, b) => {
            const dateA = parseDateFromFileName(a.fileName);
            const dateB = parseDateFromFileName(b.fileName);
            return dateB - dateA; // Sort in descending order
        });

        if(logList.status){
            setTrunkLog(logList.data);
        }else{
            setTrunkLog([]);
        }
        setTotalItem(response.data.totalItem);
       
    }
    
    const parseDateFromFileName = (fileName)  =>{
        const parts = fileName.split('_');
        const month = parseInt(parts[0]) - 1; // Month is 0-based index
        const day = parseInt(parts[1]);
        const year = parseInt(parts[2]);
        return new Date(year, month, day);
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    
    const donwloadLog = (name) => {
        // e.preventDefault();
        console.log(name);
        console.log(trunkLog);
        const selectedData = trunkLog.find((item) => item.fileName === name);
        
        if(selectedData){
            const list = JSON.parse(selectedData.fileContent);
           console.log(list);
            // console.log(JSON.parse(selectedData.fileContent));
        // const headerRow = ["Tel Number","Company_name","Route Option","In-Sync","Policy","Status","Created by", `Created at (${timeZone})`,"bap_tid_1","LA1_tid_1","bap_tid_2","LA1_tid_2","bap_tid_3","LA1_tid_3","bap_tid_4","LA1_tid_4","bap_tid_5","LA1_tid_5","bap_tid_6","LA1_tid_6","bap_tid_7","LA1_tid_7"];
        // const csv = [headerRow].concat(
        //     list.map(row => {
        //          // Replace status values with "active" or "inactive"
        //          const status = row.status == 0 ? "Active" : (row.status == 1 ? "Cancelled" : "Pending");
                 
        //          const policy = row.policy == 1 ? "top_down" : (row.policy == 2 ? "percentage" : (row.policy == 3 ? "round_robin":"none"));
        //          const created_by = row.fname + " "+row.lname;
        //          const create_at = formatDateTimeToPST(row.created_at,timeZone, role);
                
        //          // Create an array of values, including the modified status
        //          return [row.tn_number, row.cname,row.customer_name,row.in_sync, policy,status,created_by,create_at,row.bap_tid_1,row.sansay_route_id_1,row.route_id_2,row.sansay_route_id_2,row.route_id_3,row.sansay_route_id_3,row.route_id_4,row.sansay_route_id_4,row.route_id_5,row.sansay_route_id_5,row.route_id_6,row.sansay_route_id_6,row.route_id_7,row.sansay_route_id_7 ].join(',');
        //        })).join('\n');
               
           
        //      // Create a Blob containing the CSV data
        //      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
           
        //      // Trigger the file download using file-saver
        //      saveAs(blob, `${selectedData.fileName.split('/').pop().replace('.text', '').replace(/_/g, '/')}.csv`);

        let csv = '';
        
       
        
        const headers = Object.keys(list[0]);
        console.log(headers);
        csv += headers.join(',') + '\n';

        // Add data rows
        list.forEach(item => {
            headers.forEach(header => {
                csv += `"${item[header]}"` + ',';
            });
            csv += '\n';
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', `${selectedData.fileName.split('/').pop().replace('.text', '').replace(/_/g, '/')}.csv`);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }       
    }
    
    

    const checkAccess = async (access) => {
        if(role > 2 ){
            
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Trunk Sync Cron</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                   
                            
                            
                   
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>TrunkLog</th>
                                            
                                            <th>In-Sync</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {trunkLog.map((log, index) => (
                                        <tr key={index}>
                                            <td>{log.fileName.split('/').pop().replace('.text', '').replace(/_/g, '/')}</td>
                                            <td>{log.status == 1 ? 'No' : "Yes" }</td>
                                            <td><a href='#' onClick={() => donwloadLog(log.fileName)}>Download</a></td>
                                        </tr>))}
                                       
                                         </tbody>
                                </table>
                               
                                     {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
 
export default TrunkCronSync